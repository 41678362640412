import { Breadcrumb } from 'antd';
import Page from '../../components/Container';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'src/configs/path';
import CPForm from 'src/sections/cp/CPForm';
import CPCardForm from 'src/sections/cp/CPCardForm';

export default () => {
  const navigate = useNavigate();
  return (
    <>
      <Breadcrumb
        items={[
          {
            title: (
              <p style={{ cursor: 'pointer' }} onClick={() => navigate(PATH.contentProviderCard.list)}>
                Mã thẻ
              </p>
            ),
          },
          {
            title: 'Thêm mới',
          },
        ]}
      />
      <Page title="Thêm mới nhà cung cấp thẻ">
        <CPCardForm isDetail={false} />
      </Page>
    </>
  );
};
