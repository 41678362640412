import type * as Types from '../type.interface';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateBrandMutationVariables = Types.Exact<{
  input: Types.UpdateCardBrandDto;
}>;

export type UpdateBrandMutationResponse = { __typename?: 'Mutation' } & {
  updateBrand: { __typename?: 'CardBrand' } & Pick<
    Types.CardBrand,
    '_id' | 'createdAt' | 'createdBy' | 'isActive' | 'isDeleted' | 'logo' | 'name' | 'order' | 'updatedAt' | 'updatedBy'
  >;
};

export const UpdateBrandDocument = gql`
  mutation updateBrand($input: UpdateCardBrandDto!) {
    updateBrand(input: $input) {
      _id
      createdAt
      createdBy
      isActive
      isDeleted
      logo
      name
      order
      updatedAt
      updatedBy
    }
  }
`;
export function useUpdateBrandMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateBrandMutationResponse, UpdateBrandMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateBrandMutationResponse, UpdateBrandMutationVariables>(UpdateBrandDocument, options);
}
export type UpdateBrandMutationHookResult = ReturnType<typeof useUpdateBrandMutation>;
export type UpdateBrandMutationResult = Apollo.MutationResult<UpdateBrandMutationResponse>;
export type UpdateBrandMutationOptions = Apollo.BaseMutationOptions<
  UpdateBrandMutationResponse,
  UpdateBrandMutationVariables
>;
