import { PaymentBillingChannelEnum } from 'src/enums/payment.enum';
import { CheckoutTypeEnum } from './packageDisplay';

export const paymentTransactionStatus = {
  PENDING: { text: 'PENDING', color: 'warning' },
  SUCCESS: { text: 'SUCCESS', color: 'success' },
  FAIL: { text: 'FAIL', color: 'error' },
};

export const PaymentStatus = [
  { value: 'SUCCESS', label: 'Thành công' },
  { value: 'PENDING', label: 'Chờ thanh toán' },
  { value: 'FAILED', label: 'Thất bại' },
  { value: 'ROLL_BACK_FAILED', label: 'Hoàn tiền thất bại' },
  { value: 'ROLL_BACK', label: 'Hoàn tiền thành công' },
];

export const CheckoutTypeOption = [
  { value: CheckoutTypeEnum.WALLET, label: 'MOBIFONE MONEY' },
  { value: CheckoutTypeEnum.BANK, label: 'ATM NỘI ĐỊA' },
  { value: CheckoutTypeEnum.INTERNATIONAL_CARD, label: 'VISA' },
];

export const getBillingChannelName = (channel: string) => {
  switch (channel) {
    case PaymentBillingChannelEnum.APPLE_PAY:
      return 'Apple Pay';
    case PaymentBillingChannelEnum.GOOGLE_PAY:
      return 'Google Pay';
    case PaymentBillingChannelEnum.WALLET:
      return 'Mobifone Money';
    case PaymentBillingChannelEnum.BANK:
      return 'ATM nội địa';
    case PaymentBillingChannelEnum.INTERNATIONAL_CARD:
      return 'Visa';
    default:
      return '';
  }
};
