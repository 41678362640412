import { Breadcrumb } from 'antd';
import Page from '../../components/Container';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'src/configs/path';
import GameCardForm from 'src/sections/cardCode/GameCardForm';

export default () => {
  const navigate = useNavigate();
  return (
    <>
      <Breadcrumb
        items={[
          {
            title: <p style={{ cursor: 'default' }}>Mã thẻ</p>,
          },
          {
            title: (
              <p style={{ cursor: 'pointer' }} onClick={() => navigate(PATH.cardCode.listGameCard)}>
                Danh sách thẻ game
              </p>
            ),
          },
          {
            title: 'Thêm mới',
          },
        ]}
      />
      <Page title="Thêm mới thẻ game">
        <GameCardForm isDetail={false} />
      </Page>
    </>
  );
};
