import { Icon } from '@iconify/react';
import { Button, Col, Form, Input, Radio, Row, Upload, UploadFile } from 'antd';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PATH } from 'src/configs/path';
import {
  CREATE_SUCCESS_MESSAGE,
  EMAIL_FORM_MESSAGE,
  FIELD_REQUIRED,
  IMAGE_SIZE_LESS_5MB,
  INVALID_IMAGE_FORMAT,
  NO_SPACE,
  NO_SPECIAL_CHARACTER_NO_EMOJI,
  NUMBER_INTEGER,
  UPDATE_SUCCESS_MESSAGE,
} from 'src/constants/message';
import { CardBrand } from 'src/graphql/type.interface';
import { number, object, string } from 'yup';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import uploadFile from 'src/apis/service/uploadFile';
import config from 'src/serviceConfig.json';
import { beforeUploadImage } from 'src/constants/function';
import { useCreateBrandMutation } from 'src/graphql/mutations/createBrand.graphql';
import { useUpdateBrandMutation } from 'src/graphql/mutations/updateBrand.graphql';
import { MAX_IMAGE_SIZE, multiSpaceRegex, REGEX_NO_SPECIAL_CHARACTER_NO_EMOJI } from 'src/constants/constants';

type Props = { currentCP?: CardBrand; isDetail?: boolean };

const yupSync = {
  async validator({ field }: any, value: any) {
    await object({
      name: string()
        .required(FIELD_REQUIRED)
        .test({ test: (value) => !multiSpaceRegex.test(value), message: NO_SPACE })
        .matches(REGEX_NO_SPECIAL_CHARACTER_NO_EMOJI, NO_SPECIAL_CHARACTER_NO_EMOJI),
      logo: object()
        .required(FIELD_REQUIRED)
        .test({
          test: (value: any) => {
            // magic validate form upload
            return value?.fileList?.length ? true : false;
          },
          message: FIELD_REQUIRED,
        })
        .test({
          test: (value: any) => (value?.file ? /.+\.(png|jpe?g|svg)$/i.test(value?.file?.name) : true),
          message: INVALID_IMAGE_FORMAT,
        })
        .test({
          test: (value: any) => (value.file ? value?.file?.originFileObj?.size <= MAX_IMAGE_SIZE : true),
          message: IMAGE_SIZE_LESS_5MB,
        }),
    }).validateAt(field, { [field]: value });
  },
};

export default ({ currentCP, isDetail }: Props) => {
  const [form] = Form.useForm();
  const [isActivate, setIsActive] = useState<boolean>(false);
  const [logoImage, setLogoImage] = useState<UploadFile[]>([]);
  const [pathThumbnailLogo, setPathThumbnailLogo] = useState<string>('');
  const navigate = useNavigate();

  const [createBrandMutation] = useCreateBrandMutation({
    onCompleted() {
      enqueueSnackbar(CREATE_SUCCESS_MESSAGE, { variant: 'success' });
      navigate(PATH.contentProviderCard.list);
    },
    onError() {
      console.log('error');
    },
  });

  const [updateBrandMutation] = useUpdateBrandMutation({
    onCompleted() {
      enqueueSnackbar(UPDATE_SUCCESS_MESSAGE, { variant: 'success' });
      navigate(PATH.contentProviderCard.list);
    },
    onError() {
      console.log('error');
    },
  });

  useEffect(() => {
    if (currentCP) {
      const { isActive, name, logo } = currentCP;
      setIsActive(isActive as boolean);

      if (logo) {
        setPathThumbnailLogo(logo);
        setLogoImage([{ uid: '1', name: logo, url: logo, status: 'done' } as UploadFile]);
      }

      form.setFieldsValue({
        logo: {
          fileList: [
            {
              uid: '1',
              name: logo,
              url: logo,
              status: 'done',
            } as UploadFile,
          ],
        },
        isActive,
        name,
      });
    }
  }, [currentCP]);

  const customRequestUploadLogoImage = async (options: UploadRequestOption) => {
    const { file } = options;
    const formData = new FormData();

    formData.append('file', file);
    const res = await uploadFile(formData);

    if (res) {
      setPathThumbnailLogo(res.data.preview);
      setLogoImage([
        {
          uid: '1',
          name: res.data.path,
          url: `${config.DOMAIN_CDN_IMAGE}/${res.data.path}`,
          status: 'done',
        } as UploadFile,
      ]);
    }
  };

  const onFinish = async (form: CardBrand) => {
    const { name } = form;

    const data = {
      isActive: isActivate,
      logo: pathThumbnailLogo,
      name,
    };

    if (currentCP) {
      const dataUpdate = {
        _id: currentCP?._id,
        name,
        isActive: isActivate,
        logo: pathThumbnailLogo,
      };
      updateBrandMutation({ variables: { input: dataUpdate } });
    } else {
      createBrandMutation({ variables: { input: data } });
    }
  };

  return (
    <Row>
      <Col offset={3} span={18}>
        <Form form={form} labelCol={{ span: 2 }} wrapperCol={{ span: 20 }} layout="horizontal" onFinish={onFinish}>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                required={false}
                label={
                  <div>
                    Ảnh Logo <span style={{ color: 'red' }}>*</span>
                  </div>
                }
                labelCol={{ span: 24 }}
                name="logo"
                rules={[yupSync]}
              >
                <Upload
                  accept="*/*"
                  listType="picture-card"
                  multiple={false}
                  fileList={logoImage}
                  beforeUpload={beforeUploadImage}
                  customRequest={customRequestUploadLogoImage}
                  onRemove={() => {
                    setLogoImage([]);
                    setPathThumbnailLogo('');
                  }}
                  disabled={isDetail}
                >
                  <Icon icon="bi:upload" width="25" height="25" />
                </Upload>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                required={false}
                label={
                  <div>
                    Tên nhà cung cấp <span style={{ color: 'red' }}>*</span>
                  </div>
                }
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
                name="name"
                rules={[yupSync]}
              >
                <Input disabled={isDetail} />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item labelCol={{ span: 24 }} name="isActive">
                <>
                  {/* <Popconfirm
                      title="Lưu ý"
                      description={
                        'Nếu ngưng hoạt động tất cả dữ liệu liên quan đến Content Provider trên Portal sẽ bị thu hôi. Bạn có chắc chắn không?'
                      }
                      onConfirm={() => setIsActive(false)}
                      cancelText="Hủy"
                      okText="Đồng ý"
                      placement="left"
                    > */}
                  <Radio
                    checked={isActivate}
                    disabled={isDetail}
                    style={{ marginRight: '10px' }}
                    onClick={() => setIsActive(!isActivate)}
                  />{' '}
                  Phát hành
                  {/* </Popconfirm> */}
                </>
                {/* {!isActivate && (
                  <>
                    <Checkbox checked={isActivate} onChange={() => setIsActive(true)} disabled={isDetail} /> Phát hành
                  </>
                )} */}
              </Form.Item>
            </Col>
          </Row>

          {!isDetail && (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '3%' }}>
              <Button type="primary" htmlType="submit">
                Lưu
              </Button>
            </div>
          )}
        </Form>
      </Col>
    </Row>
  );
};
