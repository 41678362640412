import { Breadcrumb, Button, Card, Dropdown, Form, Input, Space, Table } from 'antd';
import BtnPermission from 'src/components/BtnPermission';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import Container from 'src/components/Container';
import { Icon } from '@iconify/react';
import { PermissionType } from 'src/enums/permissionEnum';
import { useEffect, useState } from 'react';
import { CardBrand, QueryCardBrandDto } from 'src/graphql/type.interface';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'src/configs/path';
import { useBrandsQuery } from 'src/graphql/queries/brands.graphql';
import { useDeleteBrandMutation } from 'src/graphql/mutations/deleteBrand.graphql';
import { enqueueSnackbar } from 'notistack';
import { DELETE_ERROR_MESSAGE, DELETE_SUCCESS_MESSAGE } from 'src/constants/message';

export default () => {
  const [dataCP, setDataCP] = useState<CardBrand[]>([]);
  const [filter, setFilter] = useState<QueryCardBrandDto>({ limit: 10, page: 1 });
  const [totalItem, setTotalItem] = useState<number>(0);
  const navigate = useNavigate();
  const { refetch, data } = useBrandsQuery({
    variables: { query: filter },
  });

  useEffect(() => {
    if (data) {
      setDataCP(data.brands.items);
      setTotalItem(data.brands.meta.totalItems);
    }
  });

  useEffect(() => {
    refetch();
  }, [data]);

  const [deleteBrandMutation] = useDeleteBrandMutation({
    onCompleted() {
      enqueueSnackbar(DELETE_SUCCESS_MESSAGE, { variant: 'success' });
      refetch();
    },
    onError() {
      enqueueSnackbar(DELETE_ERROR_MESSAGE, { variant: 'error' });
    },
  });

  const pagination: TablePaginationConfig = {
    pageSize: filter.limit as number,
    current: filter.page as number,
    total: totalItem,
    onChange: (page: number, pageSize: number) => {
      setFilter({
        limit: pageSize,
        page,
        keyword: filter?.keyword,
      });
    },
  };

  const handleSearchByKey = (key: string) => {
    if (key) {
      setFilter({ ...filter, page: 1, keyword: key });
    } else {
      setFilter({ ...filter, page: 1, keyword: '' });
    }
  };

  const handleItem = (record: CardBrand) => {
    const menuItems = {
      items: [
        {
          key: '1',
          label: (
            <BtnPermission
              action={PermissionType.UPDATE}
              moduleName={'card-code'}
              subModuleName={'cp-card'}
              recordId={record._id}
            />
          ),
        },
        {
          key: '',
          label: (
            <BtnPermission
              action={PermissionType.DELETE}
              moduleName={'card-code'}
              subModuleName={'cp-card'}
              onDeleteClick={() => deleteBrandMutation({ variables: { _id: record?._id } })}
            />
          ),
        },
      ],
    };
    return menuItems;
  };

  const columns: ColumnsType<CardBrand> = [
    {
      title: 'Ảnh logo',
      dataIndex: 'logo',
      key: 'logo',
      render: (logo) => <img src={logo} alt={logo} height={60} />,
    },
    {
      title: 'Tên nhà cung cấp',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => <a onClick={() => navigate(PATH.contentProviderCard.detail(record?._id))}>{text}</a>,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (isActive) => <>{isActive ? 'Hoạt động' : 'Không hoạt động'}</>,
    },

    {
      title: 'Chức năng',
      key: 'function',
      align: 'center',
      width: 120,
      render: (_, record) => (
        <Space size="middle">
          <Dropdown menu={handleItem(record)} trigger={['click']}>
            <Button type="primary" icon={<Icon icon="tabler:dots-vertical" />} />
          </Dropdown>
        </Space>
      ),
    },
  ];

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Breadcrumb
        items={[
          {
            title: (
              <p style={{ cursor: 'pointer' }} onClick={() => {}}>
                Mã thẻ
              </p>
            ),
          },
          {
            title: 'Danh sách',
          },
        ]}
      />

      <Card size="small">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            gap: '16px',
          }}
        >
          <Form.Item
            required={false}
            label={<span>Tìm kiếm theo tên nhà cung cấp</span>}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 122 }}
            style={{ margin: 0, width: 400 }}
          >
            <Input
              onChange={(e) => handleSearchByKey(e.target.value)}
              placeholder="Tìm kiếm"
              suffix={<Icon icon="ic:round-search" width={24} height={24} />}
            />
          </Form.Item>

          <BtnPermission action={PermissionType.CREATE} moduleName={'card-code'} subModuleName={'cp-card'} />
        </div>
      </Card>
      <Container title="Danh sách Nhà cung cấp thẻ">
        <Table
          columns={columns}
          dataSource={dataCP}
          rowKey="_id"
          pagination={dataCP?.length > 0 ? pagination : false}
        />
      </Container>
    </Space>
  );
};
