import {
  FrequencyTypeEnum,
  StatusEnum,
  SendTypeEnum,
  SubcriberTypeEnum,
  StatusStrategyHistoryEnum,
} from 'src/graphql/type.interface';

export const StrategyStatuses: Record<StatusEnum, string> = {
  COMING: 'Sắp diễn ra',
  FINISHED: 'Kết thúc',
  HAPPENING: 'Đang diễn ra',
};

export const StrategyHistoryStatuses: Record<StatusStrategyHistoryEnum, string> = {
  FAILED: 'Thất bại',
  SUCCESS: 'Thành công',
};

export const StrategySendTypesEnum: Record<SendTypeEnum, string> = {
  FIXED: 'FIXED',
  PERIODIC: 'PERIODIC',
};

export const StrategySendTypes = [
  {
    key: 'FIXED',
    value: 'Gửi cố định',
  },
  {
    key: 'PERIODIC',
    value: 'Gửi định kỳ',
  },
];

export const StrategySubscriberTypes: Partial<Record<SubcriberTypeEnum, string>> = {
  ALL_SUBCRIBER: 'Tất cả thuê bao active',
  SUBCRIBER_COMBO_DATA: 'Thuê bao active gói cước combo data app & portal',
  SUBCRIBER_COMBO_DATA_PORTAL: 'Thuê bao active gói cước combo data portal',
  SUBCRIBER_SUBCRIPTION: 'Thuê bao active gói cước subscription app & portal',
  SUBCRIBER_SUBCRIPTION_PORTAL: 'Thuê bao active gói cước subscription portal',
};

export const StrategySenderTypes = [
  // {
  //   key: 'CHOOSE',
  //   value: 'Theo tập thuê bao',
  // },
  {
    key: SubcriberTypeEnum.FILE,
    value: 'Tải lên danh sách',
  },

  {
    key: SubcriberTypeEnum.ALL_SUBCRIBER,
    value: 'Tất cả thuê bao active',
  },
  {
    key: SubcriberTypeEnum.SUBCRIBER_COMBO_DATA,
    value: 'Thuê bao active gói cước combo data app & portal',
  },
  {
    key: SubcriberTypeEnum.SUBCRIBER_COMBO_DATA_PORTAL,
    value: 'Thuê bao active gói cước combo data portal',
  },
  {
    key: SubcriberTypeEnum.SUBCRIBER_SUBCRIPTION,
    value: 'Thuê bao active gói cước subscription app & portal',
  },
  {
    key: SubcriberTypeEnum.SUBCRIBER_SUBCRIPTION_PORTAL,
    value: 'Thuê bao active gói cước subscription portal',
  },
];

export const StrategySenderTypeEnum = {
  CHOOSE: 'CHOOSE',
  FILE: 'FILE',
};

export const FrequencyTypes = [
  {
    key: FrequencyTypeEnum.DAY,
    value: 'Hàng ngày',
  },
  {
    key: FrequencyTypeEnum.MONTH,
    value: 'Hàng tháng',
  },
  {
    key: FrequencyTypeEnum.WEEK,
    value: 'Hàng tuần',
  },
];
