import type * as Types from '../type.interface';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GameCardsQueryVariables = Types.Exact<{
  query: Types.GameCardPaginateDto;
}>;

export type GameCardsQueryResponse = { __typename?: 'Query' } & {
  gameCards: { __typename?: 'GameCardPaginate' } & {
    items?: Types.Maybe<
      Array<
        { __typename?: 'GameCard' } & Pick<
          Types.GameCard,
          | '_id'
          | 'brandId'
          | 'brandName'
          | 'createdAt'
          | 'createdBy'
          | 'desc'
          | 'discountType'
          | 'discountValue'
          | 'isActive'
          | 'isDeleted'
          | 'name'
          | 'price'
          | 'productCode'
          | 'updatedAt'
          | 'updatedBy'
        > & {
            giftMobiGames?: Types.Maybe<
              { __typename?: 'GiftMobiGames' } & Pick<Types.GiftMobiGames, 'energy' | 'gpoint'>
            >;
          }
      >
    >;
    meta: { __typename?: 'BasePaginationMeta' } & Pick<
      Types.BasePaginationMeta,
      'currentPage' | 'itemCount' | 'itemsPerPage' | 'totalItems' | 'totalPages'
    >;
  };
};

export const GameCardsDocument = gql`
  query gameCards($query: GameCardPaginateDto!) {
    gameCards(query: $query) {
      items {
        _id
        brandId
        brandName
        createdAt
        createdBy
        desc
        discountType
        discountValue
        giftMobiGames {
          energy
          gpoint
        }
        isActive
        isDeleted
        name
        price
        productCode
        updatedAt
        updatedBy
      }
      meta {
        currentPage
        itemCount
        itemsPerPage
        totalItems
        totalPages
      }
    }
  }
`;
export function useGameCardsQuery(
  baseOptions: Apollo.QueryHookOptions<GameCardsQueryResponse, GameCardsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GameCardsQueryResponse, GameCardsQueryVariables>(GameCardsDocument, options);
}
export function useGameCardsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GameCardsQueryResponse, GameCardsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GameCardsQueryResponse, GameCardsQueryVariables>(GameCardsDocument, options);
}
export function useGameCardsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GameCardsQueryResponse, GameCardsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GameCardsQueryResponse, GameCardsQueryVariables>(GameCardsDocument, options);
}
export type GameCardsQueryHookResult = ReturnType<typeof useGameCardsQuery>;
export type GameCardsLazyQueryHookResult = ReturnType<typeof useGameCardsLazyQuery>;
export type GameCardsSuspenseQueryHookResult = ReturnType<typeof useGameCardsSuspenseQuery>;
export type GameCardsQueryResult = Apollo.QueryResult<GameCardsQueryResponse, GameCardsQueryVariables>;
