import { callApi } from 'src/apis/connection/callApi';

export enum CheckoutTypeEnum {
  WALLET = 'WALLET',
  BANK = 'BANK',
  INTERNATIONAL_CARD = 'INTERNATIONAL_CARD',
}

export default {
  getHistoryPaymentCardGame(query: any): Promise<any> {
    return callApi({ method: 'get', uri: '/payment/v1/softpin/order-history', query });
  },
  getExportExcelGameCardPaymentHistory(query: any): Promise<any> {
    return callApi({
      method: 'get',
      uri: '/payment/v1/softpin/order-history/export-excel',
      query,
      responseType: 'blob',
    });
  },
};
