import type * as Types from '../type.interface';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteCardMutationVariables = Types.Exact<{
  _id: Types.Scalars['String']['input'];
}>;

export type DeleteCardMutationResponse = { __typename?: 'Mutation' } & {
  deleteCard: { __typename?: 'Remove' } & Pick<Types.Remove, '_id' | 'status'>;
};

export const DeleteCardDocument = gql`
  mutation deleteCard($_id: String!) {
    deleteCard(_id: $_id) {
      _id
      status
    }
  }
`;
export function useDeleteCardMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteCardMutationResponse, DeleteCardMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteCardMutationResponse, DeleteCardMutationVariables>(DeleteCardDocument, options);
}
export type DeleteCardMutationHookResult = ReturnType<typeof useDeleteCardMutation>;
export type DeleteCardMutationResult = Apollo.MutationResult<DeleteCardMutationResponse>;
export type DeleteCardMutationOptions = Apollo.BaseMutationOptions<
  DeleteCardMutationResponse,
  DeleteCardMutationVariables
>;
