import {
  EventInviteStatusEnum,
  PromotionCodeStatusEnum,
  PromotionCodeTypeEnum,
  PromotionStatusEnum,
  StateUserReceivedGiftEnum,
} from 'src/graphql/type.interface';

export type TypeCommentStatus = {
  [key: string]: string;
};

interface StatusColor {
  text: string;
  color: string;
}

export type TypeActiveCommentStatus = {
  [key: string]: StatusColor;
};

export const commentStatus: TypeCommentStatus = {
  1: 'Chờ duyệt',
  2: 'Đã duyệt',
  3: 'Đã xuất bản',
  4: 'Đã từ chối',
};

export const activeCommentStatus: TypeCommentStatus = {
  PENDING: 'Duyệt',
  WAITING: 'Duyệt',
  PUBLISHED: 'Xuất bản',
  REFUSE: 'Từ chối',
};

export const commentStatusColor: TypeActiveCommentStatus = {
  1: {
    text: 'Duyệt',
    color: 'processing',
  },
  2: {
    text: 'Xuất bản',
    color: 'success',
  },
};

export const displayStatus: TypeActiveCommentStatus = {
  1: {
    text: 'Chờ duyệt',
    color: 'warning',
  },
  2: {
    text: 'Chờ xuất bản',
    color: 'processing',
  },
  3: {
    text: 'Đã xuất bản',
    color: 'success',
  },
  4: {
    text: 'Từ chối',
    color: 'error',
  },
};

export const displayStatusPromotion: TypeActiveCommentStatus = {
  [PromotionStatusEnum.IN_COMING]: {
    text: 'Sắp diễn ra',
    color: 'warning',
  },
  [PromotionStatusEnum.HAPPENING]: {
    text: 'Đang diễn ra',
    color: 'processing',
  },
  [PromotionStatusEnum.FINISHED]: {
    text: 'Đã kết thúc',
    color: 'error',
  },
};

export const displayStatusPromotionCode: TypeActiveCommentStatus = {
  [PromotionCodeStatusEnum.EXPIRED]: {
    text: 'Hết hạn',
    color: 'error',
  },
  [PromotionCodeStatusEnum.NOT_WIN_PRIZE]: {
    text: 'Không trúng giải',
    color: 'magenta',
  },
  [PromotionCodeStatusEnum.PENDING]: {
    text: 'Đang chờ',
    color: 'warning',
  },
  [PromotionCodeStatusEnum.WIN_PRIZE]: {
    text: 'Trúng giải',
    color: 'success',
  },
};

export const displayTypePromotionCode: TypeActiveCommentStatus = {
  [PromotionCodeTypeEnum.SPECIAL]: {
    text: 'Mã đặc biệt',
    color: 'success',
  },
  [PromotionCodeTypeEnum.TEMPORARILY]: {
    text: 'Mã đợt',
    color: 'magenta',
  },
};

export const displayUserGiftStatus: TypeActiveCommentStatus = {
  [StateUserReceivedGiftEnum.PUSHED_NOTI]: {
    text: 'Đã trúng thưởng',
    color: 'warning',
  },
  [StateUserReceivedGiftEnum.REAL_RECEIVED]: {
    text: 'Đã trao',
    color: 'success',
  },
  [StateUserReceivedGiftEnum.RECEIVED_IN_GAME]: {
    text: 'Đã nhận',
    color: 'purple',
  },
};

export const logStatusColor: TypeActiveCommentStatus = {
  0: {
    text: 'Gia hạn',
    color: 'processing',
  },
  1: {
    text: 'Đăng kí',
    color: 'success',
  },
  2: {
    text: 'Chờ',
    color: 'warning',
  },
  3: {
    text: 'Huỷ',
    color: 'error',
  },
  4: {
    text: 'Unknow',
    color: '',
  },
};

export const displayStatusEventInvite = {
  [EventInviteStatusEnum.UPCOMING]: {
    text: 'Sắp diễn ra',
    color: 'warning',
  },
  [EventInviteStatusEnum.HAPPENING]: {
    text: 'Đang diễn ra',
    color: 'processing',
  },
  [EventInviteStatusEnum.FINISHED]: {
    text: 'Kết thúc',
    color: 'error',
  },
};
