import { Breadcrumb } from 'antd';
import Page from '../../components/Container';
import { useNavigate, useParams } from 'react-router-dom';
import { PATH } from 'src/configs/path';
import GameCardForm from 'src/sections/cardCode/GameCardForm';
import { useState } from 'react';
import { useGameCardQuery } from 'src/graphql/queries/gameCard.graphql';
import { GameCard } from 'src/graphql/type.interface';

export default () => {
  const navigate = useNavigate();
  const _id = useParams()._id as string;
  const [currentCard, setCurrentCard] = useState<GameCard>();
  useGameCardQuery({
    variables: { _id },
    onCompleted(data) {
      setCurrentCard(data.gameCard as GameCard);
    },
    onError() {
      console.log('error');
    },
  });
  return (
    <>
      <Breadcrumb
        items={[
          {
            title: <p style={{ cursor: 'default' }}>Mã thẻ</p>,
          },
          {
            title: (
              <p style={{ cursor: 'pointer' }} onClick={() => navigate(PATH.cardCode.listGameCard)}>
                Danh sách thẻ game
              </p>
            ),
          },
          {
            title: 'Chi tiết',
          },
        ]}
      />
      <Page title="Chi tiết thẻ game">
        <GameCardForm isDetail={true} currentCard={currentCard} />
      </Page>
    </>
  );
};
