import dayjs from 'dayjs';
// import styles from './report.module.scss';
import styles from './ReportMO.module.scss';
import { useEffect, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { useQuery } from '@tanstack/react-query';
import { RangeValue } from 'rc-picker/lib/interface';
import { Breadcrumb, Button, DatePicker, Form, Select, Space, Spin } from 'antd';
import Container from 'src/components/Container';

import { GET_LIST_ERROR_MESSAGE } from 'src/constants/message';
import packageRepository, { ReportMO, UserLoginMethod } from 'src/apis/service/package';
import { saveAs } from 'file-saver';
import { Package } from 'src/types/package';

export default () => {
  const [form] = Form.useForm();
  const currentDate = dayjs();
  const previousDate = currentDate.subtract(1, 'day').format('YYYY-MM-DD');
  const eightDaysAgo = currentDate.subtract(8, 'day').format('YYYY-MM-DD');
  const [data, setData] = useState<any[]>();

  const [filter, setFilter] = useState<ReportMO>({
    startDay: eightDaysAgo,
    endDay: previousDate,
  });
  const [isLoading, setIsloading] = useState<boolean>(true);
  const [packages, setPackages] = useState<Package[]>([]);

  const { refetch } = useQuery(['getReportMO'], () => packageRepository.getReportMO({ ...filter }), {
    enabled: true,
    retry: 0,
    refetchOnWindowFocus: false,

    onSuccess: (data) => {
      if (data) {
        setData(data);
        setIsloading(false);
      }
    },
  });

  useQuery(['getPackages', filter], () => packageRepository.getPackages({ limit: 1000, page: 1 }), {
    retry: 0,
    refetchOnWindowFocus: false,
    onSuccess: ({ data, success }) => {
      if (success) {
        setPackages(data.items);
      }
    },
  });

  const onClickExportExcel = async (search: UserLoginMethod) => {
    const filename = `Thong-ke-bao-cao-MO`;
    try {
      const response = await packageRepository.getReportMOExcel({ ...search, type: undefined });
      if (response) {
        const blob = new Blob([response], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
        });
        saveAs(blob, filename);
      }
    } catch (error) {
      enqueueSnackbar(GET_LIST_ERROR_MESSAGE, { variant: 'error' });
    }
  };

  const handleSearchTimeForExcel = (date: RangeValue<dayjs.Dayjs>) => {
    const startDay = date ? dayjs(date[0]).startOf('day').format('YYYY-MM-DD') : eightDaysAgo;
    const endDay = date ? dayjs(date[1]).endOf('day').format('YYYY-MM-DD') : previousDate;
    setIsloading(true);
    setFilter({ ...filter, startDay: startDay, endDay: endDay });
  };

  useEffect(() => {
    refetch();
  }, [filter]);

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Container title="Báo cáo">
        <Breadcrumb
          items={[
            {
              title: (
                <p style={{ cursor: 'default' }} onClick={() => {}}>
                  Báo cáo
                </p>
              ),
            },
            {
              title: (
                <p style={{ cursor: 'default' }} onClick={() => {}}>
                  MO
                </p>
              ),
            },
          ]}
        />
        <>
          <div style={{ display: 'flex', gap: '24px' }}>
            <Form form={form} style={{ display: 'flex', gap: '24px' }}>
              <Form.Item
                required={false}
                label={<span>Tìm theo thời gian</span>}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                name="searchExcelByDate"
                initialValue={[dayjs(eightDaysAgo), dayjs(previousDate)]}
              >
                <DatePicker.RangePicker
                  format={'DD/MM/YYYY'}
                  style={{ width: '100%' }}
                  onChange={(date: RangeValue<dayjs.Dayjs>) => handleSearchTimeForExcel(date)}
                  disabledDate={(current: dayjs.Dayjs | null) =>
                    current ? current.isAfter(dayjs().subtract(1, 'day').endOf('day')) : false
                  }
                  allowClear
                />
              </Form.Item>

              <Form.Item
                required={false}
                label={<span>Gói cước</span>}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Select
                  style={{ width: '100%' }}
                  placeholder="Tất cả"
                  onChange={(e) => setFilter({ ...filter, packageCode: e })}
                >
                  {packages.map((pkg) => (
                    <Select.Option value={pkg.code}>{pkg.code}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>

            <Button
              type="primary"
              style={{ marginTop: '40px' }}
              disabled={(data ?? [])?.length < 1}
              onClick={() => onClickExportExcel(filter)}
            >
              Xuất File
            </Button>
          </div>
          {isLoading ? (
            <div style={{ display: 'flex', justifyContent: 'center', height: '500px', alignItems: 'center' }}>
              <Spin />
            </div>
          ) : (data ?? [])?.length > 0 ? (
            <div style={{ overflowX: 'auto', maxWidth: '100%', marginTop: '43px' }}>
              <table style={{ width: '100%', minWidth: '800px', overflow: 'hidden' }}>
                <tbody className={styles.bodyTable}>
                  <tr className={styles.headerTable}>
                    <td>Tên gói</td>
                    {data?.[0]?.value?.map((i: any) => (
                      <td className={styles.tdTable}>{i.date}</td>
                    ))}
                    <td>Lũy kế tháng</td>
                  </tr>
                  {data?.map((item) => (
                    <>
                      <tr className={styles.PackageName}>
                        <td colSpan={item.value.length + 2}>{item?.packageCode}</td>
                      </tr>
                      <tr className={styles.valueStatusTable}>
                        <>
                          <td>Thất bại</td>
                          {item?.value?.map((i: any) => (
                            <>
                              <td className={styles.tdTable}>{i.failed_count}</td>
                            </>
                          ))}
                          <td>{item?.value?.[0]?.cumulativeFailed}</td>
                        </>
                      </tr>
                      <tr className={styles.valueStatusTable}>
                        <>
                          <td>Thành công</td>
                          {item?.value?.map((i: any) => (
                            <td className={styles.tdTable}>{i.success_count}</td>
                          ))}
                          <td>{item?.value?.[0]?.cumulativeSuccess}</td>
                        </>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div style={{ fontSize: '20px', textAlign: 'center' }}>Không có giá trị nào!</div>
          )}
        </>
      </Container>
    </Space>
  );
};
