import type * as Types from '../type.interface';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BrandsQueryVariables = Types.Exact<{
  query: Types.QueryCardBrandDto;
}>;

export type BrandsQueryResponse = { __typename?: 'Query' } & {
  brands: { __typename?: 'CardBrandPaginate' } & {
    items: Array<
      { __typename?: 'CardBrand' } & Pick<
        Types.CardBrand,
        | '_id'
        | 'createdAt'
        | 'createdBy'
        | 'isActive'
        | 'isDeleted'
        | 'logo'
        | 'name'
        | 'order'
        | 'updatedAt'
        | 'updatedBy'
      >
    >;
    meta: { __typename?: 'BasePaginationMeta' } & Pick<
      Types.BasePaginationMeta,
      'currentPage' | 'itemCount' | 'itemsPerPage' | 'totalItems' | 'totalPages'
    >;
  };
};

export const BrandsDocument = gql`
  query brands($query: QueryCardBrandDto!) {
    brands(query: $query) {
      items {
        _id
        createdAt
        createdBy
        isActive
        isDeleted
        logo
        name
        order
        updatedAt
        updatedBy
      }
      meta {
        currentPage
        itemCount
        itemsPerPage
        totalItems
        totalPages
      }
    }
  }
`;
export function useBrandsQuery(baseOptions: Apollo.QueryHookOptions<BrandsQueryResponse, BrandsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BrandsQueryResponse, BrandsQueryVariables>(BrandsDocument, options);
}
export function useBrandsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BrandsQueryResponse, BrandsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BrandsQueryResponse, BrandsQueryVariables>(BrandsDocument, options);
}
export function useBrandsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<BrandsQueryResponse, BrandsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BrandsQueryResponse, BrandsQueryVariables>(BrandsDocument, options);
}
export type BrandsQueryHookResult = ReturnType<typeof useBrandsQuery>;
export type BrandsLazyQueryHookResult = ReturnType<typeof useBrandsLazyQuery>;
export type BrandsSuspenseQueryHookResult = ReturnType<typeof useBrandsSuspenseQuery>;
export type BrandsQueryResult = Apollo.QueryResult<BrandsQueryResponse, BrandsQueryVariables>;
