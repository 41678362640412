import { Button, Col, Form, Input, InputNumber, Radio, Row, Select, UploadFile } from 'antd';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PATH } from 'src/configs/path';
import {
  CREATE_SUCCESS_MESSAGE,
  FIELD_REQUIRED,
  NO_EMOJI,
  NO_SPACE,
  NO_SPECIAL_CHARACTER_NO_EMOJI,
  UPDATE_SUCCESS_MESSAGE,
} from 'src/constants/message';
import {
  CardBrand,
  CreateGameCardDto,
  DiscountTypeEnum,
  GameCard,
  UpdateGameCardDto,
} from 'src/graphql/type.interface';
import { number, object, string } from 'yup';
import { useBrandsQuery } from 'src/graphql/queries/brands.graphql';
import { useCreateCardMutation } from 'src/graphql/mutations/createCard.graphql';
import { useUpdateCardMutation } from 'src/graphql/mutations/updateCard.graphql';
import { multiSpaceRegex, regex, REGEX_NO_SPECIAL_CHARACTER_NO_EMOJI } from 'src/constants/constants';

type Props = { currentCard?: GameCard; isDetail?: boolean };

const yupSync = {
  async validator({ field }: any, value: any) {
    await object({
      productCode: string()
        .required(FIELD_REQUIRED)
        .test({ test: (value) => !multiSpaceRegex.test(value), message: NO_SPACE })
        .matches(regex, NO_EMOJI),
      name: string()
        .required(FIELD_REQUIRED)
        .test({ test: (value) => !multiSpaceRegex.test(value), message: NO_SPACE })
        .matches(regex, NO_EMOJI),
      desc: string()
        .test({ test: (value: any) => !multiSpaceRegex.test(value), message: NO_SPACE })
        .matches(regex, NO_EMOJI)
        .notRequired(),
      price: number()
        .positive('Mệnh giá thẻ phải là một số nguyên dương lớn hơn 0')
        .integer('Mệnh giá thẻ phải là một số nguyên dương')
        .typeError('Mệnh giá thẻ phải là một số nguyên dương lớn hơn 0')
        .required(FIELD_REQUIRED),
      brandId: string().required(FIELD_REQUIRED),
      checkoutType: string().required(FIELD_REQUIRED),
      discountValue: number().min(0, 'Giảm giá thẻ phải lớn hơn 0').notRequired(),
      gpoint: number()
        .positive('Mệnh giá thẻ phải là một số nguyên dương lớn hơn 0')
        .integer('Mệnh giá thẻ phải là một số nguyên dương')
        .typeError('Mệnh giá thẻ phải là một số nguyên dương lớn hơn 0')
        .notRequired(),
      energy: number()
        .positive('Mệnh giá thẻ phải là một số nguyên dương lớn hơn 0')
        .integer('Mệnh giá thẻ phải là một số nguyên dương')
        .typeError('Mệnh giá thẻ phải là một số nguyên dương lớn hơn 0')
        .notRequired(),
    }).validateAt(field, { [field]: value });
  },
};

export default ({ currentCard, isDetail }: Props) => {
  const [form] = Form.useForm();
  const [isActivate, setIsActive] = useState<boolean>(false);
  const [dataCp, setDataCp] = useState<CardBrand[]>([]);
  const [isPickTypeDiscount, setIsPickTypeDiscount] = useState<boolean>(false);
  const navigate = useNavigate();

  const OptionSaleOff = [
    {
      label: 'Theo %',
      value: DiscountTypeEnum.PERCENT,
    },
    {
      label: 'Theo số tiền',
      value: DiscountTypeEnum.MONEY,
    },
  ];

  useBrandsQuery({
    variables: { query: { limit: 10000, page: 1, isActive: true } },
    onCompleted(data) {
      setDataCp(data.brands.items);
    },
  });

  const [createCardMutation] = useCreateCardMutation({
    onCompleted() {
      enqueueSnackbar(CREATE_SUCCESS_MESSAGE, { variant: 'success' });
      navigate(PATH.cardCode.listGameCard);
    },
    onError() {
      console.log('error');
    },
  });

  const [updateCardMutation] = useUpdateCardMutation({
    onCompleted() {
      enqueueSnackbar(UPDATE_SUCCESS_MESSAGE, { variant: 'success' });
      navigate(PATH.cardCode.listGameCard);
    },
    onError() {
      console.log('error');
    },
  });

  useEffect(() => {
    if (currentCard) {
      const { isActive, name, brandId, discountType, discountValue, price, productCode, giftMobiGames, desc } =
        currentCard;
      setIsActive(isActive as boolean);

      discountType && setIsPickTypeDiscount(true);

      form.setFieldsValue({
        isActive,
        name,
        brandId,
        discountType,
        discountValue,
        price,
        productCode,
        energy: giftMobiGames?.energy,
        gpoint: giftMobiGames?.gpoint,
        desc,
      });
    }
  }, [currentCard]);

  const onFinish = async (form: any) => {
    const { name, brandId, price, productCode, desc, discountType, discountValue, energy, gpoint } = form;

    const data: CreateGameCardDto = {
      isActive: isActivate,
      name,
      brandId,
      price,
      productCode,
      desc,
      discountType: discountType ?? null,
      discountValue: discountValue ?? null,
    };

    if (energy || gpoint) {
      data.giftMobiGames = {
        energy: energy,
        gpoint: gpoint,
      };
    }

    if (currentCard) {
      const dataUpdate: UpdateGameCardDto = {
        _id: currentCard?._id,
        isActive: isActivate,
        name,
        brandId,
        price,
        productCode,
        desc,
        discountType: discountType ?? null,
        discountValue: discountValue ?? null,
      };

      if (energy || gpoint) {
        dataUpdate.giftMobiGames = {
          energy: energy,
          gpoint: gpoint,
        };
      }
      updateCardMutation({ variables: { input: dataUpdate } });
    } else {
      createCardMutation({ variables: { input: data } });
    }
  };

  return (
    <Row>
      <Col offset={3} span={18}>
        <Form form={form} labelCol={{ span: 2 }} wrapperCol={{ span: 20 }} layout="horizontal" onFinish={onFinish}>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                required={false}
                label={
                  <div>
                    Mã sản phẩm <span style={{ color: 'red' }}>*</span>
                  </div>
                }
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
                name="productCode"
                rules={[yupSync]}
              >
                <Input disabled={isDetail} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                required={false}
                label={
                  <div>
                    Tên sản phẩm <span style={{ color: 'red' }}>*</span>
                  </div>
                }
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
                name="name"
                rules={[yupSync]}
              >
                <Input disabled={isDetail} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                required={false}
                label={<div>Mô tả</div>}
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
                name="desc"
                rules={[yupSync]}
              >
                <Input disabled={isDetail} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                required={false}
                label={
                  <div>
                    Mệnh giá thẻ <span style={{ color: 'red' }}>*</span>
                  </div>
                }
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
                name="price"
                rules={[yupSync]}
              >
                <InputNumber style={{ width: '100%' }} disabled={isDetail} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                required={false}
                label={
                  <div>
                    Nhà cung cấp <span style={{ color: 'red' }}>*</span>
                  </div>
                }
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
                name="brandId"
                rules={[yupSync]}
              >
                <Select placeholder="Chọn nhà cung cấp" disabled={isDetail}>
                  {dataCp?.map((cp: CardBrand) => (
                    <Select.Option value={cp?._id}>{cp?.name}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col
              span={24}
              style={{
                border: '1px solid #1677ff',
                padding: '24px',
                borderRadius: '10px',
                margin: '4% 0',
                position: 'relative',
                marginTop: '30px',
              }}
            >
              <p
                style={{
                  position: 'absolute',
                  top: '-20px',
                  left: '16px',
                  background: 'white',
                  fontSize: '18px',
                  color: '#1677ff',
                }}
              >
                Ưu đãi mua gói
              </p>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    label="Energy"
                    labelCol={{ span: 24 }}
                    rules={[yupSync]}
                    wrapperCol={{ span: 24 }}
                    name="energy"
                  >
                    <InputNumber style={{ width: '100%' }} disabled={isDetail} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="GPoint"
                    labelCol={{ span: 24 }}
                    rules={[yupSync]}
                    wrapperCol={{ span: 24 }}
                    name="gpoint"
                  >
                    <InputNumber style={{ width: '100%' }} disabled={isDetail} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              <Form.Item
                required={false}
                label={<div>Giảm giá</div>}
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
                name="discountType"
              >
                <Select
                  placeholder="Chọn loại giảm giá"
                  onChange={(e) => {
                    setIsPickTypeDiscount(e ? true : false);
                    form.setFieldsValue({
                      discountValue: undefined,
                    });
                  }}
                  allowClear
                  disabled={isDetail}
                >
                  {OptionSaleOff?.map((option) => (
                    <Select.Option value={option.value}>{option.label}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {isPickTypeDiscount && (
              <Col span={24}>
                <Form.Item wrapperCol={{ span: 24 }} labelCol={{ span: 24 }} name="discountValue" rules={[yupSync]}>
                  <InputNumber style={{ width: '100%' }} disabled={isDetail} />
                </Form.Item>
              </Col>
            )}

            <Col span={24}>
              <Form.Item labelCol={{ span: 24 }} name="isActive">
                <>
                  {/* <Popconfirm
                      title="Lưu ý"
                      description={
                        'Nếu ngưng hoạt động tất cả dữ liệu liên quan đến Content Provider trên Portal sẽ bị thu hôi. Bạn có chắc chắn không?'
                      }
                      onConfirm={() => setIsActive(false)}
                      cancelText="Hủy"
                      okText="Đồng ý"
                      placement="left"
                    > */}
                  <Radio
                    checked={isActivate}
                    disabled={isDetail}
                    style={{ marginRight: '10px' }}
                    onClick={() => setIsActive(!isActivate)}
                  />{' '}
                  Phát hành
                  {/* </Popconfirm> */}
                </>
                {/* {!isActivate && (
                  <>
                    <Checkbox checked={isActivate} onChange={() => setIsActive(true)} disabled={isDetail} /> Phát hành
                  </>
                )} */}
              </Form.Item>
            </Col>
          </Row>

          {!isDetail && (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '3%' }}>
              <Button type="primary" htmlType="submit">
                Lưu
              </Button>
            </div>
          )}
        </Form>
      </Col>
    </Row>
  );
};
