import { ContentProviderTypeEnum, TypeSliderEnum } from 'src/graphql/type.interface';

export const PATH_AUTH = {
  login: '/auth/login',
  register: '/auth/register',
  loginUnprotected: '/auth/login-unprotected',
  registerUnprotected: '/auth/register-unprotected',
  verify: '/auth/verify',
  resetPassword: '/auth/reset-password',
  newPassword: '/auth/new-password',
};

export const PATH = {
  permissionDenied: '/permission-denied',
  account: {
    group: {
      list: (moduleName: string) => `/${moduleName}/${moduleName}-group/list`,
      create: (moduleName: string) => `/${moduleName}/${moduleName}-group/create`,
      edit: (_id: string) => `/group/edit/${_id}`,
    },
    administrator: {
      list: (moduleName: string) => `/${moduleName}/${moduleName}-administrator/list`,
      create: (moduleName: string) => `/${moduleName}/${moduleName}-administrator/create`,
      edit: (_id: string) => `/administrator/edit/${_id}`,
    },
    customer: {
      create: (moduleName: string) => `/${moduleName}/${moduleName}-customer/create`,
      list: (moduleName: string) => `/${moduleName}/${moduleName}-customer/list`,
      profile: '/customer/profile',
      account: '/customer/customer',
      edit: (_id: string) => `/customer/edit/${_id}`,
      detail: (_id: string) => `/account/account-customer/detail/${_id}`,
    },
    contact: {
      list: '/account/contact/list',
    },
    regionalCompany: {
      list: '/account/regional-company/list',
      create: `/account/regional-company/create`,
      edit: (_id: string) => `account/regional-company/edit/${_id}`,
    },
  },
  servicePackage: {
    root: '/service-packge',
    list: '/service-packge/list',
    create: '/service-packge/create',
    edit: (_id: string) => `/service-packge/edit/${_id}`,
    clone: (_id: string) => `/service-packge/clone/${_id}`,
  },
  home: {
    slider: {
      list: (type: TypeSliderEnum) => `/home/home-slider/list/${type}`,
      create: (type: TypeSliderEnum) => `/home/home-slider/create/${type}`,
      edit: (_id: string) => `/home/home-slider/edit/${_id}`,
      detail: (_id: string) => `/home/home-slider/detail/${_id}`,
    },
  },
  game: {
    list: '/game/g234/list',
    create: '/game/g234/create',
    edit: (_id: string) => `/game/edit/${_id}`,
    g1: {
      list: `/game/g1/list`,
      create: `/game/g1/create`,
      edit: (_id: string) => `/game/g1/edit/${_id}`,
      detail: (_id: string) => `/game/g1/detail/${_id}`,
    },
    slider: {
      list: (type: TypeSliderEnum) => `/game/game-slider/list/${type}`,
      create: (type: TypeSliderEnum) => `/game/game-slider/create/${type}`,
      edit: (_id: string) => `/game/slider/edit/${_id}`,
      detail: (_id: string) => `/game/game-slider/detail/${_id}`,
    },
    feedback: {
      list: '/game/feedback/list',
      detail: (_id: string) => `/game/feedback/detail/${_id}`,
    },
    tableRankings: {
      list: '/game/score-statistics/list',
    },
  },

  dataGame: {
    list: '/game/data/list',
  },
  news: {
    root: '/news/all-news',
    list: '/news/all-news/list',
    create: '/news/all-news/create',
    detail: (_id: string) => `/news/all-news/detail/${_id}`,
    edit: (_id: string) => `/news/all-news/edit/${_id}`,
    slider: {
      list: (type: TypeSliderEnum) => `/news/news-slider/list/${type}`,
      create: (type: TypeSliderEnum) => `/news/news-slider/create/${type}`,
      edit: (_id: string) => `/news/news-slider/edit/${_id}`,
      detail: (_id: string) => `/news/news-slider/detail/${_id}`,
    },
  },
  tournament: {
    rootRoom: '/tournament-online/room',
    listRoom: '/tournament-online/room/list',
    createRoom: '/tournament-online/room/create',
    editRoom: (_id: string) => `/tournament-online/room/edit/${_id}`,
    detailRoom: (_id: string) => `/tournament-online/room/detail/${_id}`,
    rootEvent: '/tournament-online/event',
    listEvent: '/tournament-online/event/list',
    createEvent: '/tournament-online/event/create',
    detailEvent: (_id: string) => `/tournament-online/event/detail/${_id}`,
    editEvent: (_id: string) => `/tournament-online/event/edit/${_id}`,
    editRanking: (_id: string) => `/tournament-online/event/ranking-tournament-online/edit/${_id}`,
    listHistoryPvP: '/tournament-online/PvP/list',
  },
  tag: {
    root: '/tag',
    list: (moduleName: string, type: string) => `/${moduleName}/${moduleName}-tag/list/${type}`,
    create: (type: string) => `/tag/create/${type}`,
    edit: (_id: string) => `/tag/edit/${_id}`,
  },
  category: {
    root: '/:slug/category',
    list: (moduleName: string, type: string) => `/${moduleName}/${moduleName}-category/list/${type}`,
    create: (moduleName: string, type: string) => `/${moduleName}/${moduleName}-category/create/${type}`,
    edit: (moduleName: string, _id: string) => `/${moduleName}/${moduleName}-category/edit/${_id}`,
    detail: (moduleName: string, _id: string) => `/${moduleName}/${moduleName}-category/detail/${_id}`,
  },
  slider: {
    root: '/slider',
    list: (moduleName: string, type: string) => `/${moduleName}/${moduleName}-slider/list/${type}`,
    create: (type: string) => `/slider/create/${type}`,
    edit: (_id: string) => `/slider/edit/${_id}`,
    detail: (moduleName: string, _id: string) => `/${moduleName}/${moduleName}-slider/detail/${_id}`,
  },
  video: {
    root: '/video',
    list: '/video/all-video/list',
    create: '/video/all-video/create',
    edit: (_id: string) => `/video/all-video/edit/${_id}`,
    detail: (_id: string) => `/video/all-video/detail/${_id}`,
  },

  ads: {
    root: '/ads/all-ads',
    list: '/ads/all-ads/list',
    edit: (_id: string) => `/ads/all-ads/edit/${_id}`,
    detail: (_id: string) => `/ads/all-ads/detail/${_id}`,
  },

  mission: {
    root: '/mission/all-mission',
    list: '/mission/all-mission/list',
    create: '/mission/all-mission/create',
    edit: (_id: string) => `/mission/all-mission/edit/${_id}`,
    detail: (_id: string) => `/mission/all-mission/detail/${_id}`,
  },

  // missionv2: {
  //   root: '/mission-v2',
  //   list: '/mission-v2/list',
  //   create: '/mission-v2/create',
  //   edit: (_id: string) => `/mission-v2/edit/${_id}`,
  //   detail: (_id: string) => `/mission-v2/detail/${_id}`,
  // },

  loyalty: {
    root: '/loyalty',
    list: '/loyalty/list',
    edit: (_id: string) => `/loyalty/edit/${_id}`,
  },
  promotion: {
    root: '/promotion',
    list: '/promotion/promotion-campain/list',
    create: '/promotion/promotion-campain/create',
    edit: (_id: string) => `/promotion/promotion-campain/edit/${_id}`,
    detail: (_id: string) => `/promotion/promotion-campain/detail/${_id}`,
    payRewards: '/promotion/pay-rewards',
    historyPayRewards: '/promotion/history-pay-rewards',
    plusBonus: '/promotion/plus-bonus',
  },
  promotionIntergration: {
    promotionIntergration: '/promotion-intergration/promotion-intergration-programs/list',
    createPromotionIntergration: '/promotion-intergration/promotion-intergration-programs/create',
    editPromotionIntergration: (_id: string) => `/promotion-intergration/promotion-intergration-programs/edit/${_id}`,
    promotionConfig: '/promotion-intergration/promotion-config',
    createPromotionConfig: '/promotion-intergration/promotion-config/create',
    editPromotionConfig: (_id: string) => `/promotion-intergration/promotion-config/edit/${_id}`,
    promotionGift: '/promotion-intergration/promotion-gift',
    createPromotionGift: '/promotion-intergration/promotion-gift/create',
    editPromotionGift: (_id: string) => `/promotion-intergration/promotion-gift/edit/${_id}`,
    giftsGiving: '/promotion-intergration/gifts-giving',
  },
  promotionArtifact: {
    list: '/promotion-artifacts/all-promotion-artifacts/list',
    create: '/promotion-artifacts/create',
    edit: (_id: string) => `/promotion-artifacts/edit/${_id}`,
    detail: (_id: string) => `/promotion-artifacts/all-promotion-artifacts/detail/${_id}`,
    promotionCode: '/promotion-artifacts/all-promotion-artifacts/codes/list',
    editPromotionCode: (_id: string) => `/promotion-artifacts/codes/edit/${_id}`,
    promotionReward: '/promotion-artifacts/all-promotion-artifacts/rewards/list',
    createPromotionReward: '/promotion-artifacts/all-promotion-artifacts/rewards/create',
    editPromotionReward: (_id: string) => `/promotion-artifacts/rewards/${_id}`,
    detailPromotionReward: (_id: string) => `/promotion-artifacts/all-promotion-artifacts/rewards/detail/${_id}`,
  },
  // configLdap: {
  //   root: '/config',
  //   edit: () => '/config/ldap-info/edit',
  // },
  configPackage: {
    root: '/config',
    edit: () => '/config/package/edit',
  },
  configMobileIntro: {
    root: '/config',
    list: '/config/mobileIntro/list',
    create: '/config/mobileIntro/create',
    edit: (_id: string) => `/config/mobileIntro/edit/${_id}`,
  },
  configPortalIntro: {
    root: '/config',
    list: '/config/portal-intro/list',
    create: '/config/portal-intro/create',
    edit: (_id: string) => `config/portal-intro/edit/${_id}`,
  },
  configModule: {
    root: '/config',
    list: '/config/module/list',
    create: '/config/module/create',
    edit: (_id: string) => `/config/module/edit/${_id}`,
    detail: (_id: string) => `/config/module/detail/${_id}`,
    listSubModule: `/config/sub-module/list`,
    createSubModule: (_parentId: string) => `/config/sub-module/create/${_parentId}`,
    editSubModule: (_id: string) => `/config/sub-module/edit/${_id}`,
    detailSubModule: (_id: string) => `/config/sub-module/detail/${_id}`,
  },
  logContent: {
    root: '/log',
    list: '/log/content/list',
    detail: (_id: string) => `/log/content/detail/${_id}`,
  },
  logAccount: {
    root: '/log',
    list: '/log/account/list',
    detail: (_id: string) => `/log/account/detail/${_id}`,
  },
  logPackage: {
    root: '/log',
    list: '/log/package/list',
  },
  tokenToEnergy: {
    root: 'config',
    list: '/config/tokenToEnergy/edit',
  },
  giftEnergy: {
    root: 'config',
    list: '/config/give-energy/edit',
  },
  package: {
    package: {
      root: '/package/all-package',
      list: '/package/all-package/list',
      create: '/package/all-package/create',
      edit: (id: number) => `/package/all-package/edit/${id}`,
      detail: (id: number) => `/package/all-package/detail/${id}`,
    },
    register: {
      root: '/package',
      list: '/package/register-online/list',
      detail: (id: number) => `/package/register/detail/${id}`,
    },
    history: {
      root: '/package',
      list: '/package/register-offline/list',
      detail: (id: number) => `/package/register-offline/detail/${id}`,
    },
    transactionHistory: {
      root: '/package',
      list: '/package/transaction-history/list',
    },
    specialCodeCP: {
      root: '/package',
      list: '/package/command-special/list',
    },
    paymentHistory: {
      root: '/package/payment-history',
      list: '/package/payment-history/list',
      detail: (transactionId: string) => `/package/payment-history/detail/${transactionId}`,
    },
    historyCDRMM: {
      root: '/package/history-cdr-mm/list',
      list: 'package/history-cdr-mm/list',
    },
  },
  tournamentOffline: {
    root: 'tournament-offline/all-tour-offline',
    list: '/tournament-offline/all-tour-offline/list',
    listVideo: 'tournament-offline/video/list',
    listNew: '/tournament-offline/all-tour-offline/news/list',
    detailNew: (_id: string) => `/tournament-offline/all-tour-offline/news/detail/${_id}`,
    edit: (_id: string) => `/tournament-offline/edit/${_id}`,
    detail: (_id: string) => `/tournament-offline/all-tour-offline/detail/${_id}`,
    editTeamInTour: (_id: string) => `tournament-offline/detail/team-in-tournament/${_id}`,
  },
  footer: {
    list: '/config/footer/list',
    create: '/config/footer/create',
    edit: (_id: string) => `/config/footer/edit/${_id}`,
    detail: (_id: string) => `/config/footer/detail/${_id}`,
  },
  report: {
    root: '/report',
    revenue: {
      root: '/report/revenue',
    },
    subscriptionCancellation: {
      root: '/report/subscription-cancellation',
    },
    revenueDataIP: {
      root: 'report/data-ip',
    },
    firstLoad: {
      root: 'report/first-purchase-continuous',
    },
    revenueSubCommand: {
      root: 'report/revenue-sub-command',
    },
    reportMO: {
      root: 'report/report-mo',
    },
  },
  reportContentProvider: {
    root: '/content-provider-report',
    cpReportStatistic: {
      root: '/report-cp-statistic',
    },
  },
  contentProvider: {
    root: '/content-provider/all-CP',
    list: '/content-provider/all-CP/list',
    create: '/content-provider/all-CP/create',
    edit: (_id: string, type: ContentProviderTypeEnum) => `/content-provider/all-CP/edit/${type}/${_id}`,
    detail: (_id: string, type: ContentProviderTypeEnum) => `/content-provider/all-CP/detail/${type}/${_id}`,
  },
  contentProviderMedia: {
    root: '/content-provider/media',
    list: '/content-provider/media/list',
    create: '/content-provider/media/create',
    edit: (_id: string) => `/content-provider/media/edit/${_id}`,
    detail: (_id: string) => `/content-provider/media/detail/${_id}`,
  },
  contentProviderCard: {
    root: '/card-code/cp-card',
    list: '/card-code/cp-card/list',
    create: '/card-code/cp-card/create',
    edit: (_id: string) => `/card-code/cp-card/edit/${_id}`,
    detail: (_id: string) => `/card-code/cp-card/detail/${_id}`,
  },
  cardCode: {
    root: '/card-code',
    listGameCard: '/card-code/game-card',
    detailGameCard: (_id: string) => `/card-code/game-card/detail/${_id}`,
    historyPayment: '/card-code/game-card-payment-history',
  },
  login2fa: {
    root: '/login-by-otp',
  },
  ranking: {
    root: '/pvp/pvp-ranking',
    list: '/pvp/pvp-ranking/list',
    detail: (_id: string) => `/pvp/pvp-ranking/detail/${_id}`,
  },
  strategy: {
    root: '/strategy',
    list: '/strategy/list',
    detail: (_id: string) => `/strategy/detail/${_id}`,
  },
  milestone: {
    root: '/achievements-milestone',
    list: '/achievements-milestone/milestone-list',
    detail: (_id: string) => `/achievements-milestone/milestone/detail/${_id}`,
  },
  achievement: {
    root: '/achievements-milestone',
    list: '/achievements-milestone/achievement-list',
    historyReceivedAchievement: '/achievements-milestone/history-received-achievement',
    detail: (_id: string) => `/achievements-milestone/achievement/detail/${_id}`,
  },
  items: {
    root: '/items',
    list: '/items/item-list',
    detail: (_id: string) => `/items/item-list/detail/${_id}`,
  },
  friend: {
    root: '/friend',
    listInvite: '/friend/invite/list',
    listEventInvite: '/friend/event-invite/list',
  },
  strategySMS: {
    root: '/strategy-send-history',
    list: '/strategy-send-history/sms-history/list',
    detail: (_id: string) => `/strategy-send-history/sms-history/detail/${_id}`,
  },
};
