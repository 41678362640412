import { Breadcrumb, Button, Card, Dropdown, Form, Input, Select, Space, Table, Tag } from 'antd';
import BtnPermission from 'src/components/BtnPermission';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import Container from 'src/components/Container';
import { Icon } from '@iconify/react';
import { PermissionType } from 'src/enums/permissionEnum';
import { useEffect, useState } from 'react';
import { CardBrand, ContentProviderG1, GameCard, GameCardPaginateDto } from 'src/graphql/type.interface';
import { useGameCardsQuery } from 'src/graphql/queries/gameCards.graphql';
import { enqueueSnackbar } from 'notistack';
import { DELETE_ERROR_MESSAGE, DELETE_SUCCESS_MESSAGE } from 'src/constants/message';
import { useBrandsQuery } from 'src/graphql/queries/brands.graphql';
import { useDeleteCardMutation } from 'src/graphql/mutations/deleteCard.graphql';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'src/configs/path';

export default () => {
  const [dataCard, setDataCard] = useState<GameCard[]>([]);
  const [filter, setFilter] = useState<GameCardPaginateDto>({ limit: 10, page: 1 });
  const [totalItem, setTotalItem] = useState<number>(0);
  const [dataCp, setDataCp] = useState<CardBrand[]>([]);
  const navigate = useNavigate();
  const { refetch, data } = useGameCardsQuery({
    variables: { query: filter },
  });

  useEffect(() => {
    if (data) {
      setDataCard(data.gameCards.items as GameCard[]);
      setTotalItem(data.gameCards.meta.totalItems);
    }
  });

  useEffect(() => {
    refetch();
  }, [data]);

  useBrandsQuery({
    variables: { query: { limit: 10000, page: 1 } },
    onCompleted(data) {
      setDataCp(data.brands.items as CardBrand[]);
    },
  });

  const [deleteCardMutation] = useDeleteCardMutation({
    onCompleted() {
      enqueueSnackbar(DELETE_SUCCESS_MESSAGE, { variant: 'success' });
      refetch();
    },
    onError() {
      enqueueSnackbar(DELETE_ERROR_MESSAGE, { variant: 'error' });
    },
  });

  const pagination: TablePaginationConfig = {
    pageSize: filter.limit as number,
    current: filter.page as number,
    total: totalItem,
    onChange: (page: number, pageSize: number) => {
      setFilter({
        ...filter,
        limit: pageSize,
        page,
      });
    },
  };

  const handleSearchByKey = (key: string) => {
    if (key) {
      setFilter({ ...filter, page: 1, keyword: key });
    } else {
      setFilter({ ...filter, page: 1, keyword: '' });
    }
  };

  const handleItem = (record: GameCard) => {
    const menuItems = {
      items: [
        {
          key: '1',
          label: (
            <BtnPermission
              action={PermissionType.UPDATE}
              moduleName={'card-code'}
              subModuleName={'game-card'}
              recordId={record._id}
            />
          ),
        },
        {
          key: '',
          label: (
            <BtnPermission
              action={PermissionType.DELETE}
              moduleName={'card-code'}
              subModuleName={'game-card'}
              onDeleteClick={() => deleteCardMutation({ variables: { _id: record?._id } })}
            />
          ),
        },
      ],
    };
    return menuItems;
  };

  const columns: ColumnsType<GameCard> = [
    {
      title: 'Mã sản phẩm',
      dataIndex: 'productCode',
      key: 'productCode',
      render: (productCode, record) => (
        <a onClick={() => navigate(PATH.cardCode.detailGameCard(record?._id))}>{productCode}</a>
      ),
    },
    {
      title: 'Nhà cung cấp',
      dataIndex: 'brandName',
      key: 'brandName',
    },
    {
      title: 'Tên',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Mệnh giá thẻ',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (isActive) => <>{isActive ? 'Đã phát hành' : 'Ngưng phát hành'}</>,
    },

    {
      title: 'Chức năng',
      key: 'function',
      align: 'center',
      width: 120,
      render: (_, record) => (
        <Space size="middle">
          <Dropdown menu={handleItem(record)} trigger={['click']}>
            <Button type="primary" icon={<Icon icon="tabler:dots-vertical" />} />
          </Dropdown>
        </Space>
      ),
    },
  ];

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Breadcrumb
        items={[
          {
            title: (
              <p style={{ cursor: 'pointer' }} onClick={() => {}}>
                Mã thẻ
              </p>
            ),
          },
          {
            title: 'Danh sách',
          },
        ]}
      />

      <Card size="small">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            gap: '16px',
          }}
        >
          <div style={{ display: 'flex', gap: '20px' }}>
            <Form.Item
              required={false}
              label={<span>Tìm kiếm theo tên sản phẩm</span>}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 122 }}
              style={{ margin: 0, width: 400 }}
            >
              <Input
                onChange={(e) => handleSearchByKey(e.target.value)}
                placeholder="Tìm kiếm theo tên sản phẩm"
                suffix={<Icon icon="ic:round-search" width={20} height={20} />}
              />
            </Form.Item>

            <Form.Item
              required={false}
              label={<span>Nhà cung cấp</span>}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 122 }}
              style={{ margin: 0, width: 400 }}
            >
              <Select onChange={(e) => setFilter({ ...filter, brandId: e })} allowClear placeholder="Tất cả">
                {dataCp?.map((cp: any) => (
                  <Select.Option value={cp._id}>{cp.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <BtnPermission action={PermissionType.CREATE} moduleName={'card-code'} subModuleName={'game-card'} />
        </div>
      </Card>
      <Container title="Danh sách thẻ game">
        <Table
          columns={columns}
          dataSource={dataCard}
          rowKey="_id"
          pagination={dataCard?.length > 0 ? pagination : false}
        />
      </Container>
    </Space>
  );
};
