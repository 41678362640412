import type * as Types from '../type.interface';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BrandQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;

export type BrandQueryResponse = { __typename?: 'Query' } & {
  brand?: Types.Maybe<
    { __typename?: 'CardBrand' } & Pick<
      Types.CardBrand,
      | '_id'
      | 'createdAt'
      | 'createdBy'
      | 'isActive'
      | 'isDeleted'
      | 'logo'
      | 'name'
      | 'order'
      | 'updatedAt'
      | 'updatedBy'
    >
  >;
};

export const BrandDocument = gql`
  query brand($id: String!) {
    brand(id: $id) {
      _id
      createdAt
      createdBy
      isActive
      isDeleted
      logo
      name
      order
      updatedAt
      updatedBy
    }
  }
`;
export function useBrandQuery(baseOptions: Apollo.QueryHookOptions<BrandQueryResponse, BrandQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BrandQueryResponse, BrandQueryVariables>(BrandDocument, options);
}
export function useBrandLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrandQueryResponse, BrandQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BrandQueryResponse, BrandQueryVariables>(BrandDocument, options);
}
export function useBrandSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<BrandQueryResponse, BrandQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BrandQueryResponse, BrandQueryVariables>(BrandDocument, options);
}
export type BrandQueryHookResult = ReturnType<typeof useBrandQuery>;
export type BrandLazyQueryHookResult = ReturnType<typeof useBrandLazyQuery>;
export type BrandSuspenseQueryHookResult = ReturnType<typeof useBrandSuspenseQuery>;
export type BrandQueryResult = Apollo.QueryResult<BrandQueryResponse, BrandQueryVariables>;
