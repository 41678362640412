import type * as Types from '../type.interface';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GameCardQueryVariables = Types.Exact<{
  _id: Types.Scalars['ID']['input'];
}>;

export type GameCardQueryResponse = { __typename?: 'Query' } & {
  gameCard?: Types.Maybe<
    { __typename?: 'GameCard' } & Pick<
      Types.GameCard,
      | '_id'
      | 'brandId'
      | 'brandName'
      | 'createdAt'
      | 'createdBy'
      | 'desc'
      | 'discountType'
      | 'discountValue'
      | 'isActive'
      | 'isDeleted'
      | 'name'
      | 'price'
      | 'productCode'
      | 'updatedAt'
      | 'updatedBy'
    > & {
        giftMobiGames?: Types.Maybe<{ __typename?: 'GiftMobiGames' } & Pick<Types.GiftMobiGames, 'energy' | 'gpoint'>>;
      }
  >;
};

export const GameCardDocument = gql`
  query gameCard($_id: ID!) {
    gameCard(_id: $_id) {
      _id
      brandId
      brandName
      createdAt
      createdBy
      desc
      discountType
      discountValue
      giftMobiGames {
        energy
        gpoint
      }
      isActive
      isDeleted
      name
      price
      productCode
      updatedAt
      updatedBy
    }
  }
`;
export function useGameCardQuery(baseOptions: Apollo.QueryHookOptions<GameCardQueryResponse, GameCardQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GameCardQueryResponse, GameCardQueryVariables>(GameCardDocument, options);
}
export function useGameCardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GameCardQueryResponse, GameCardQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GameCardQueryResponse, GameCardQueryVariables>(GameCardDocument, options);
}
export function useGameCardSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GameCardQueryResponse, GameCardQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GameCardQueryResponse, GameCardQueryVariables>(GameCardDocument, options);
}
export type GameCardQueryHookResult = ReturnType<typeof useGameCardQuery>;
export type GameCardLazyQueryHookResult = ReturnType<typeof useGameCardLazyQuery>;
export type GameCardSuspenseQueryHookResult = ReturnType<typeof useGameCardSuspenseQuery>;
export type GameCardQueryResult = Apollo.QueryResult<GameCardQueryResponse, GameCardQueryVariables>;
