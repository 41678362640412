import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Breadcrumb, Card, Form, Select, Space, Table, DatePicker, Row, Col, Button } from 'antd';
import dayjs from 'dayjs';

import Container from 'src/components/Container';
import {
  StrategySendHistoryPaginateDto,
  StrategySendHistory,
  StatusStrategyHistoryEnum,
  StrategySms,
} from 'src/graphql/type.interface';
import { StrategyHistoryStatuses, StrategyStatuses } from 'src/constants/stategySendHistory';
import { useStrategorySendHistoryQuery } from 'src/graphql/queries/strategorySendHistory.graphql';
import { useStrategoriesQuery } from 'src/graphql/queries/strategories.graphql';
import SMSRespon from 'src/apis/service/smsHistory';
import { saveAs } from 'file-saver';
import { enqueueSnackbar } from 'notistack';
import { GET_LIST_ERROR_MESSAGE } from 'src/constants/message';

export default () => {
  const navigate = useNavigate();
  const [strategyData, setStrategyData] = useState<StrategySendHistory[]>([]);
  const [filter, setFilter] = useState<StrategySendHistoryPaginateDto>({ limit: 10, page: 1 });
  const [totalItems, setTotalItems] = useState<number>(0);
  const [strategies, setStrategies] = useState<StrategySms[]>([]);

  const { refetch, data, error } = useStrategorySendHistoryQuery({
    variables: { query: filter },
  });

  useStrategoriesQuery({
    variables: { query: { limit: 1000, page: 1 } },
    onCompleted(data) {
      setStrategies(data.strategories.items || []);
    },
  });

  const pagination: TablePaginationConfig = {
    pageSize: filter.limit as number,
    current: filter.page as number,
    total: totalItems,
    onChange: (page: number, pageSize: number) => {
      setFilter({ ...filter, limit: pageSize, page });
    },
  };

  useEffect(() => {
    if (data) {
      setStrategyData(data.strategorySendHistory.items || []);
      setTotalItems(data.strategorySendHistory.meta.totalItems);
    }
  }, [data, error]);

  const onClickExportExcel = async (search: any) => {
    const filename = `Thong-ke-bao-cao-lich-su-gui-tin-nhan-${dayjs(search.startDay).format('DD-MM-YYYY')}-den-${dayjs(
      search.endDay,
    ).format('DD-MM-YYYY')}`;

    try {
      const response = await SMSRespon.getReportExcelSMSHistory({ ...search });
      if (response) {
        const blob = new Blob([response], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
        });

        saveAs(blob, filename);
      }
    } catch (error) {
      enqueueSnackbar(GET_LIST_ERROR_MESSAGE, { variant: 'error' });
    }
  };

  useEffect(() => {
    refetch();
  }, [filter]);

  const columns: ColumnsType<StrategySendHistory> = [
    {
      title: 'ID',
      dataIndex: '_id',
      key: '_id',
      width: 120,
      render: (_, record, index) => {
        return index + 1 + ((filter.page || 1) - 1) * (filter.limit || 10);
      },
    },
    {
      title: 'Chiến dịch',
      dataIndex: 'strategyName',
      key: 'strategyName',
    },
    {
      title: 'Thuê bao',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Nội dung',
      dataIndex: 'contentMessage',
      key: 'contentMessage',
    },
    {
      title: 'Thời gian gửi',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => <>{dayjs(createdAt).format('DD/MM/YYYY HH:mm:ss')}</>,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      render: (status) => StrategyHistoryStatuses[status as StatusStrategyHistoryEnum],
    },
  ];

  const handleSearchWithPublishTime = (date: any) => {
    if (date) {
      setFilter({
        ...filter,
        page: 1,
        startTime: date ? dayjs(date[0]).startOf('day').format('YYYY/MM/DD') : date,
        endTime: date ? dayjs(date[1]).endOf('day').format('YYYY/MM/DD') : date,
      });
    } else {
      setFilter({
        ...filter,
        limit: 10,
        page: 1,
        startTime: undefined,
        endTime: undefined,
      });
    }
  };

  const handleSearchByStrategy = (strategyId: any) => {
    setFilter({ ...filter, page: 1, strategyId: strategyId });
  };

  const handleSearchByStatus = (status: any) => {
    setFilter({ ...filter, page: 1, status: status });
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Breadcrumb
        items={[
          {
            title: (
              <p style={{ cursor: 'pointer' }} onClick={() => navigate(``)}>
                Lịch sử chiến dịch thông báo
              </p>
            ),
          },
          { title: 'Danh sách' },
        ]}
      />

      <Card size="small">
        <Row gutter={24}>
          <Col span={5}>
            <Form.Item
              required={false}
              label={<span>Chiến dịch</span>}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Select
                style={{ width: '100%' }}
                placeholder="Tất cả"
                allowClear
                onChange={(e) => handleSearchByStrategy(e)}
              >
                {strategies.map((item) => (
                  <Select.Option value={item._id}>{item.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item
              required={false}
              label={<span>Trạng thái</span>}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Select
                style={{ width: '100%' }}
                placeholder="Tất cả"
                allowClear
                onChange={(e) => handleSearchByStatus(e)}
              >
                {Object.entries(StrategyHistoryStatuses).map(([value, label]) => (
                  <Select.Option value={value}>{label}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              required={false}
              label={<span>Tìm theo khoảng thời tìm kiếm</span>}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <DatePicker.RangePicker
                format={'DD/MM/YYYY'}
                style={{ width: '100%' }}
                onChange={(date) => handleSearchWithPublishTime(date)}
                inputReadOnly
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item required={false} label={<span></span>} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
              <Button type="primary" onClick={() => onClickExportExcel(filter)}>
                Xuất Excel
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Card>
      <Container title="Lịch sử gửi tin nhắn">
        <Table
          columns={columns}
          dataSource={strategyData}
          rowKey="_id"
          pagination={strategyData?.length > 0 ? pagination : false}
        />
      </Container>
    </Space>
  );
};
