import { Breadcrumb } from 'antd';
import Page from '../../components/Container';
import { useNavigate, useParams } from 'react-router-dom';
import { PATH } from 'src/configs/path';
import CPCardForm from 'src/sections/cp/CPCardForm';
import { useBrandQuery } from 'src/graphql/queries/brand.graphql';
import { useState } from 'react';
import { CardBrand } from 'src/graphql/type.interface';

export default () => {
  const navigate = useNavigate();

  const id = useParams()?._id as string;

  const [currentCp, setCurrentCp] = useState<CardBrand>();

  useBrandQuery({
    variables: { id },
    onCompleted(data) {
      setCurrentCp(data.brand as CardBrand);
    },
    onError() {
      console.log('error');
    },
  });

  return (
    <>
      <Breadcrumb
        items={[
          {
            title: (
              <p style={{ cursor: 'pointer' }} onClick={() => navigate(PATH.contentProviderCard.list)}>
                Mã thẻ
              </p>
            ),
          },
          {
            title: 'Chi tiết',
          },
        ]}
      />
      <Page title="Chi tiết nhà cung cấp thẻ">
        <CPCardForm isDetail={true} currentCP={currentCp} />
      </Page>
    </>
  );
};
