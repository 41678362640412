import { callApi } from 'src/apis/connection/callApi';
import { RestResponse } from 'src/types/api';
import { BasePaginationMeta } from 'src/types/publisher';

export type Invite = {
  createdAt: string;
  updatedAt: string;
  id: string;
  userRefId: string;
  username: string;
  phone: string;
  inviteId: string;
  type: InviteType;
  milestoneId: string | null;
  level: number;
  rewardCount: number;
  rewardType: InviteTypeReward;
  rewardValue: string;
  milestone: any;
};

export enum InviteType {
  REFERRAL_COUNT = 'REFERRAL_COUNT',
  REFERRAL_MILESTONE = 'REFERRAL_MILESTONE',
}

export enum InviteTypeReward {
  ENERGY = 'ENERGY',
  GPOINT = 'GPOINT',
  ITEM = 'ITEM',
}

export enum EventInviteTypeReward {
  ENERGY = 'ENERGY',
  GPOINT = 'GPOINT',
  ITEM = 'ITEM',
  DATA = 'DATA',
  MAIN_ACCOUNT = 'MAIN_ACCOUNT',
  BONUS_ACCOUNT = 'BONUS_ACCOUNT',
}

export type HistoryClaimInvite = {
  limit: number;
  page: number;
  type: InviteType;
  rewardType?: InviteTypeReward;
  keyword?: string;
  startTime?: string;
  endTime?: string;
  level?: number;
  milestoneId?: string;
};

type HistoryClaimInviteResponse = {
  data: { items: Invite[]; meta: BasePaginationMeta };
} & RestResponse;

export type HistoryClaimEventInvite = {
  limit: number;
  page: number;
  rewardType?: EventInviteTypeReward;
  keyword?: string;
  startTime?: string;
  endTime?: string;
  eventInviteId?: string;
};

type HistoryClaimEventInviteResponse = {
  data: { items: any[]; meta: BasePaginationMeta };
} & RestResponse;

export default {
  getHistoryClaimInvite(query: HistoryClaimInvite): Promise<HistoryClaimInviteResponse> {
    return callApi({ method: 'get', uri: '/mobile/invite/history-claim-invite', query });
  },

  getHistoryClaimEventInvite(query: HistoryClaimEventInvite): Promise<HistoryClaimEventInviteResponse> {
    const queryParams = new URLSearchParams();

    Object.entries(query).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item) => queryParams.append(key, item));
      } else if (value !== undefined && value !== null) {
        queryParams.append(key, String(value));
      }
    });

    return callApi({
      method: 'get',
      uri: `/mobile/event-invite/history-claim-event-invite?${queryParams.toString()}`,
    });
  },

  exportExcel(query: HistoryClaimEventInvite): Promise<any> {
    return callApi({
      method: 'get',
      uri: '/mobile/event-invite/excel',
      query,
      responseType: 'blob',
    });
  },
};
