import type * as Types from '../type.interface';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateCardMutationVariables = Types.Exact<{
  input: Types.CreateGameCardDto;
}>;

export type CreateCardMutationResponse = { __typename?: 'Mutation' } & {
  createCard: { __typename?: 'GameCard' } & Pick<
    Types.GameCard,
    | '_id'
    | 'brandId'
    | 'brandName'
    | 'createdAt'
    | 'createdBy'
    | 'desc'
    | 'discountType'
    | 'discountValue'
    | 'isActive'
    | 'isDeleted'
    | 'name'
    | 'price'
    | 'productCode'
    | 'updatedAt'
    | 'updatedBy'
  > & {
      giftMobiGames?: Types.Maybe<{ __typename?: 'GiftMobiGames' } & Pick<Types.GiftMobiGames, 'energy' | 'gpoint'>>;
    };
};

export const CreateCardDocument = gql`
  mutation createCard($input: CreateGameCardDto!) {
    createCard(input: $input) {
      _id
      brandId
      brandName
      createdAt
      createdBy
      desc
      discountType
      discountValue
      giftMobiGames {
        energy
        gpoint
      }
      isActive
      isDeleted
      name
      price
      productCode
      updatedAt
      updatedBy
    }
  }
`;
export function useCreateCardMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCardMutationResponse, CreateCardMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCardMutationResponse, CreateCardMutationVariables>(CreateCardDocument, options);
}
export type CreateCardMutationHookResult = ReturnType<typeof useCreateCardMutation>;
export type CreateCardMutationResult = Apollo.MutationResult<CreateCardMutationResponse>;
export type CreateCardMutationOptions = Apollo.BaseMutationOptions<
  CreateCardMutationResponse,
  CreateCardMutationVariables
>;
