import type * as Types from '../type.interface';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateBrandMutationVariables = Types.Exact<{
  input: Types.CreateCardBrandDto;
}>;

export type CreateBrandMutationResponse = { __typename?: 'Mutation' } & {
  createBrand: { __typename?: 'CardBrand' } & Pick<
    Types.CardBrand,
    '_id' | 'createdAt' | 'createdBy' | 'isActive' | 'isDeleted' | 'logo' | 'name' | 'order' | 'updatedAt' | 'updatedBy'
  >;
};

export const CreateBrandDocument = gql`
  mutation createBrand($input: CreateCardBrandDto!) {
    createBrand(input: $input) {
      _id
      createdAt
      createdBy
      isActive
      isDeleted
      logo
      name
      order
      updatedAt
      updatedBy
    }
  }
`;
export function useCreateBrandMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateBrandMutationResponse, CreateBrandMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateBrandMutationResponse, CreateBrandMutationVariables>(CreateBrandDocument, options);
}
export type CreateBrandMutationHookResult = ReturnType<typeof useCreateBrandMutation>;
export type CreateBrandMutationResult = Apollo.MutationResult<CreateBrandMutationResponse>;
export type CreateBrandMutationOptions = Apollo.BaseMutationOptions<
  CreateBrandMutationResponse,
  CreateBrandMutationVariables
>;
