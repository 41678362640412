import type * as Types from '../type.interface';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteBrandMutationVariables = Types.Exact<{
  _id: Types.Scalars['String']['input'];
}>;

export type DeleteBrandMutationResponse = { __typename?: 'Mutation' } & {
  deleteBrand: { __typename?: 'Remove' } & Pick<Types.Remove, '_id' | 'status'>;
};

export const DeleteBrandDocument = gql`
  mutation deleteBrand($_id: String!) {
    deleteBrand(_id: $_id) {
      _id
      status
    }
  }
`;
export function useDeleteBrandMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteBrandMutationResponse, DeleteBrandMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteBrandMutationResponse, DeleteBrandMutationVariables>(DeleteBrandDocument, options);
}
export type DeleteBrandMutationHookResult = ReturnType<typeof useDeleteBrandMutation>;
export type DeleteBrandMutationResult = Apollo.MutationResult<DeleteBrandMutationResponse>;
export type DeleteBrandMutationOptions = Apollo.BaseMutationOptions<
  DeleteBrandMutationResponse,
  DeleteBrandMutationVariables
>;
