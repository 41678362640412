import { TablePaginationConfig } from 'antd';
import type { FilterValue } from 'antd/es/table/interface';
import { BadRequestError } from 'src/constants/message';
import { InviteTypeEnum } from 'src/graphql/type.interface';
export const getVideoDimensions = (url: string): Promise<{ height: number; width: number }> => {
  return new Promise((resolve) => {
    const video = document.createElement('video');

    video.addEventListener(
      'loadedmetadata',
      function () {
        resolve({ height: this.videoHeight, width: this.videoWidth });
      },
      false,
    );
    video.src = url;
  });
};

export const getImageDimensions = (url: string): Promise<{ height: number; width: number }> => {
  return new Promise((resolve) => {
    const image = document.createElement('img');

    image.addEventListener(
      'load',
      function () {
        resolve({ height: this.height, width: this.width });
      },
      false,
    );
    image.src = url;
  });
};

export interface TableParams {
  pagination: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue>;
}

export let timeoutId: ReturnType<typeof setTimeout>;
export const debounce = <F extends (...args: any) => any>(func: F, time: number) => {
  return function (this: any, ...args: any) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, time);
  };
};

export const extractYouTubeVideoID = (url: string) => {
  const startIndex = url.indexOf('=') + 1;
  const endIndex = url.indexOf('&', startIndex);

  return endIndex !== -1 ? url.substring(startIndex, endIndex) : url.substring(startIndex);
};

export const messageReQuestError = (code: any) => {
  return BadRequestError.find((item) => Number(item.code) === code)?.message;
};

export const formatMoneyToVND = (money: number) => {
  return money.toLocaleString('it-IT', { style: 'currency', currency: 'VND' });
};

export const switchTypeInvite = (type: InviteTypeEnum) => {
  switch (type) {
    case InviteTypeEnum.REFERRAL_COUNT:
      return 'Thường';
    case InviteTypeEnum.REFERRAL_MILESTONE:
      return 'Liên kết Mốc';
    default:
      return '';
  }
};

export const rename = (str: string): string => {
  return (
    str
      .trim()
      .toLowerCase()
      .replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
      .replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
      .replace(/ì|í|ị|ỉ|ĩ/g, 'i')
      .replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
      .replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
      .replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
      .replace(/đ/g, 'd')
      //eslint-disable-next-line
      .replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, ' ')
      .replace(/ + /g, ' ')
      .replace(/ /g, '-')
  );
};
