import type * as Types from '../type.interface';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StrategorySendHistoryQueryVariables = Types.Exact<{
  query: Types.StrategySendHistoryPaginateDto;
}>;

export type StrategorySendHistoryQueryResponse = { __typename?: 'Query' } & {
  strategorySendHistory: { __typename?: 'StrategySendHistoryPaginate' } & {
    items?: Types.Maybe<
      Array<
        { __typename?: 'StrategySendHistory' } & Pick<
          Types.StrategySendHistory,
          | '_id'
          | 'contentMessage'
          | 'createdAt'
          | 'createdBy'
          | 'date'
          | 'jobId'
          | 'phoneNumber'
          | 'response'
          | 'status'
          | 'strategyId'
          | 'strategyName'
          | 'updatedAt'
          | 'updatedBy'
        >
      >
    >;
    meta: { __typename?: 'BasePaginationMeta' } & Pick<
      Types.BasePaginationMeta,
      'currentPage' | 'itemCount' | 'itemsPerPage' | 'totalItems' | 'totalPages'
    >;
  };
};

export const StrategorySendHistoryDocument = gql`
  query strategorySendHistory($query: StrategySendHistoryPaginateDto!) {
    strategorySendHistory(query: $query) {
      items {
        _id
        contentMessage
        createdAt
        createdBy
        date
        jobId
        phoneNumber
        response
        status
        strategyId
        strategyName
        updatedAt
        updatedBy
      }
      meta {
        currentPage
        itemCount
        itemsPerPage
        totalItems
        totalPages
      }
    }
  }
`;
export function useStrategorySendHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<StrategorySendHistoryQueryResponse, StrategorySendHistoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StrategorySendHistoryQueryResponse, StrategorySendHistoryQueryVariables>(
    StrategorySendHistoryDocument,
    options,
  );
}
export function useStrategorySendHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<StrategorySendHistoryQueryResponse, StrategorySendHistoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StrategorySendHistoryQueryResponse, StrategorySendHistoryQueryVariables>(
    StrategorySendHistoryDocument,
    options,
  );
}
export function useStrategorySendHistorySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    StrategorySendHistoryQueryResponse,
    StrategorySendHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<StrategorySendHistoryQueryResponse, StrategorySendHistoryQueryVariables>(
    StrategorySendHistoryDocument,
    options,
  );
}
export type StrategorySendHistoryQueryHookResult = ReturnType<typeof useStrategorySendHistoryQuery>;
export type StrategorySendHistoryLazyQueryHookResult = ReturnType<typeof useStrategorySendHistoryLazyQuery>;
export type StrategorySendHistorySuspenseQueryHookResult = ReturnType<typeof useStrategorySendHistorySuspenseQuery>;
export type StrategorySendHistoryQueryResult = Apollo.QueryResult<
  StrategorySendHistoryQueryResponse,
  StrategorySendHistoryQueryVariables
>;
