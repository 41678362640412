import type * as Types from '../type.interface';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StrategoriesQueryVariables = Types.Exact<{
  query: Types.StrategyPaginateDto;
}>;

export type StrategoriesQueryResponse = { __typename?: 'Query' } & {
  strategories: { __typename?: 'StrategySMSPaginate' } & {
    items?: Types.Maybe<
      Array<
        { __typename?: 'StrategySMS' } & Pick<
          Types.StrategySms,
          | '_id'
          | 'contentMessage'
          | 'cpId'
          | 'createdAt'
          | 'createdBy'
          | 'day'
          | 'deleted'
          | 'endDate'
          | 'filePath'
          | 'frequency'
          | 'name'
          | 'sendType'
          | 'startDate'
          | 'status'
          | 'subcriberType'
          | 'time'
          | 'updatedAt'
          | 'updatedBy'
        >
      >
    >;
    meta: { __typename?: 'BasePaginationMeta' } & Pick<
      Types.BasePaginationMeta,
      'currentPage' | 'itemCount' | 'itemsPerPage' | 'totalItems' | 'totalPages'
    >;
  };
};

export const StrategoriesDocument = gql`
  query strategories($query: StrategyPaginateDto!) {
    strategories(query: $query) {
      items {
        _id
        contentMessage
        cpId
        createdAt
        createdBy
        day
        deleted
        endDate
        filePath
        frequency
        name
        sendType
        startDate
        status
        subcriberType
        time
        updatedAt
        updatedBy
      }
      meta {
        currentPage
        itemCount
        itemsPerPage
        totalItems
        totalPages
      }
    }
  }
`;
export function useStrategoriesQuery(
  baseOptions: Apollo.QueryHookOptions<StrategoriesQueryResponse, StrategoriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StrategoriesQueryResponse, StrategoriesQueryVariables>(StrategoriesDocument, options);
}
export function useStrategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<StrategoriesQueryResponse, StrategoriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StrategoriesQueryResponse, StrategoriesQueryVariables>(StrategoriesDocument, options);
}
export function useStrategoriesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<StrategoriesQueryResponse, StrategoriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<StrategoriesQueryResponse, StrategoriesQueryVariables>(StrategoriesDocument, options);
}
export type StrategoriesQueryHookResult = ReturnType<typeof useStrategoriesQuery>;
export type StrategoriesLazyQueryHookResult = ReturnType<typeof useStrategoriesLazyQuery>;
export type StrategoriesSuspenseQueryHookResult = ReturnType<typeof useStrategoriesSuspenseQuery>;
export type StrategoriesQueryResult = Apollo.QueryResult<StrategoriesQueryResponse, StrategoriesQueryVariables>;
