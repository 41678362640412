import { ApolloClient, InMemoryCache, ApolloLink, concat, HttpLink } from '@apollo/client';
import config from 'src/serviceConfig.json';
import { TOKEN_EXPIRED_MESSAGE } from 'src/constants/message';
import { getToken, isValidToken, removeToken } from 'src/utils/jwt';
import { onError } from '@apollo/client/link/error';
import { enqueueSnackbar } from 'notistack';
import { messageReQuestError } from 'src/utils/common';

const httpLink = new HttpLink({ uri: `${config['API_GATEWAY']}/cms/graphql`, fetchOptions: {} });

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(() => {
    const token = getToken() as string;

    if (isValidToken(token)) {
      return { headers: { authorization: `Bearer ${token}` } };
    }
    removeToken();
    alert(TOKEN_EXPIRED_MESSAGE);
    window.location.replace(`${window.location.origin}/login`);
  });
  return forward(operation);
});

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    console.log('graphQLErrors', graphQLErrors);

    enqueueSnackbar(
      messageReQuestError(Number(graphQLErrors?.[0]?.message))
        ? messageReQuestError(Number(graphQLErrors?.[0]?.message))
        : graphQLErrors?.[0]?.message,
      { variant: 'error' },
    );
  }
});

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  connectToDevTools: false,
  link: ApolloLink.from([authMiddleware, errorLink, httpLink]),
});
