import { useEffect, useMemo, useState } from 'react';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Breadcrumb, Card, Col, DatePicker, Input, Row, Select, Space, Table, Tag } from 'antd';
import { PATH } from 'src/configs/path';
import Container from 'src/components/Container';
import paymentService, { ReportPaymentQuery } from 'src/apis/service/payment';
import packageService from 'src/apis/service/package';
import { Payment } from 'src/types/payment';
import { PaymentBillingChannelEnum, PaymentTransactionStatusEnum } from 'src/enums/payment.enum';
import { getBillingChannelName, paymentTransactionStatus } from 'src/constants/payment';
import dayjs from 'dayjs';
import { FORMAT_TIME } from 'src/constants/common';
import { Package } from 'src/types/package';
import { RangeValue } from 'rc-picker/lib/interface';
import { CloseCircleTwoTone } from '@ant-design/icons';

const OPTIONS_PAYMENT_BILLING_CHANNEL = [
  { label: 'Apple Pay', value: PaymentBillingChannelEnum.APPLE_PAY },
  { label: 'Google Pay', value: PaymentBillingChannelEnum.GOOGLE_PAY },
  { label: 'MobiFone Money', value: PaymentBillingChannelEnum.WALLET },
  { label: 'ATM nội địa', value: PaymentBillingChannelEnum.BANK },
  { label: 'Visa', value: PaymentBillingChannelEnum.INTERNATIONAL_CARD },
];

export default () => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState<Payment[]>([]);
  const [filter, setFilter] = useState<ReportPaymentQuery>({ limit: 10, page: 1 });
  const [totalItems, setTotalItems] = useState<number>(0);
  const [packages, setPackages] = useState<Package[]>([]);

  const { refetch } = useQuery(['getPaymentHistory', filter], () => paymentService.getReportPayment(filter), {
    retry: 0,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setTableData(data?.data?.items);
      setTotalItems(data.data?.meta?.totalItems);
    },
  });

  useQuery(['getPackages'], () => packageService.getPackages({ limit: 10000, page: 1 }), {
    retry: 0,
    refetchOnWindowFocus: false,
    onSuccess: ({ data, success }) => {
      if (success) {
        setPackages(data.items);
      }
    },
  });

  const pagination: TablePaginationConfig = {
    pageSize: filter.limit as number,
    current: filter.page as number,
    total: totalItems,
    onChange: (page: number, pageSize: number) => {
      setFilter({ ...filter, limit: pageSize, page });
    },
  };

  useEffect(() => {
    refetch();
  }, [filter]);

  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Transaction ID',
      dataIndex: 'transactionId',
      key: 'transactionId',
      render: (value) => <a onClick={() => navigate(PATH.package.paymentHistory.detail(`${value}`))}>{value}</a>,
    },
    {
      title: 'UID',
      dataIndex: 'transactionBy',
      key: 'transactionBy',
    },
    {
      title: 'Thuê bao',
      dataIndex: 'isdn',
      key: 'isdn',
    },
    {
      title: 'Tên tài khoản',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Gói cước',
      dataIndex: 'packageCode',
      key: 'packageCode',
    },
    {
      title: 'Giá gói',
      dataIndex: 'packagePrice',
      key: 'packagePrice',
    },
    {
      title: 'Giá trị thanh toán',
      dataIndex: 'billingAmount',
      key: 'billingAmount',
    },
    {
      title: 'Phương thức thanh toán',
      dataIndex: 'billingChannel',
      key: 'billingChannel',
      render: (value) => <>{getBillingChannelName(value)}</>,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      render: (value: PaymentTransactionStatusEnum) => (
        <>{<Tag color={paymentTransactionStatus[value].color}>{paymentTransactionStatus[value].text}</Tag>}</>
      ),
    },
    {
      title: 'Ngày giao dịch',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (value) => (
        <>
          <span>{`${dayjs(value).format(FORMAT_TIME)}`}</span>
        </>
      ),
    },
    {
      title: 'Xác thực',
      dataIndex: 'isVerification',
      key: 'isVerification',
      render: (value) => (
        <>
          {value !== null ? (
            value ? (
              <div style={{ color: '#22ec3e' }}>OK</div>
            ) : (
              <div style={{ color: '#ec2222' }}>NOT OK</div>
            )
          ) : (
            ''
          )}
        </>
      ),
    },
    {
      title: 'Lý do',
      dataIndex: 'reason',
      key: 'reason',
    },
  ];

  const handleChangeInput = (e: any) => {
    setFilter({ ...filter, page: 1, keyword: e.target.value || undefined });
  };

  const handleSearchWithCreateTime = (date: RangeValue<dayjs.Dayjs>) => {
    setFilter({
      ...filter,
      page: 1,
      startTime: date ? dayjs(date[0]).startOf('day').toISOString() : undefined,
      endTime: date ? dayjs(date[1]).endOf('day').toISOString() : undefined,
    });
  };

  const handleChangePackage = (e: any) => {
    setFilter({ ...filter, page: 1, packageCode: e || undefined });
  };

  const handleChangeBillingChannel = (e: any) => {
    setFilter({ ...filter, page: 1, billingChannel: e || undefined });
  };

  const valueSelect = useMemo(() => {
    return packages.map((item) => ({
      value: item.code,
      label: item.code,
    }));
  }, [packages]);

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Breadcrumb
        items={[
          {
            title: (
              <p style={{ cursor: 'pointer' }} onClick={() => navigate(PATH.package.package.list)}>
                Gói cước
              </p>
            ),
          },
          {
            title: <p style={{ cursor: 'default' }}>Lịch sử thanh toán</p>,
          },
          { title: 'Danh sách' },
        ]}
      />
      <Card size="small">
        <Row gutter={24} justify={'end'}>
          <Col span={5}>
            <span>Tìm kiếm</span>
            <Input onChange={(e) => handleChangeInput(e)} placeholder="Tìm theo username hoặc UID" />
          </Col>
          <Col span={5}>
            <span>Khoảng thời gian tìm kiếm</span>
            <DatePicker.RangePicker
              format={'DD/MM/YYYY HH:mm'}
              style={{ width: '100%' }}
              showTime
              onChange={(date) => handleSearchWithCreateTime(date)}
              inputReadOnly
            />
          </Col>
          <Col span={3}>
            <p style={{ marginBottom: 0 }}>Gói cước</p>
            <Select
              style={{ width: '90%' }}
              allowClear
              clearIcon={<CloseCircleTwoTone twoToneColor="#FF0000" style={{ fontSize: '15px', top: '46%' }} />}
              showSearch
              optionFilterProp="label"
              filterSort={(optionA: any, optionB: any) =>
                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
              }
              onChange={handleChangePackage}
              placeholder="Gói cước"
              options={valueSelect}
            />
          </Col>
          <Col span={5}>
            <p style={{ marginBottom: 0 }}>Phương thức thanh toán</p>

            <Select
              style={{ width: '90%' }}
              allowClear
              onChange={handleChangeBillingChannel}
              options={OPTIONS_PAYMENT_BILLING_CHANNEL}
              placeholder="Tất cả"
            />
          </Col>
        </Row>
      </Card>
      <div>
        <Container title="Lịch sử thanh toán">
          <Table columns={columns} dataSource={tableData} rowKey="id" pagination={pagination} />
        </Container>
      </div>
    </Space>
  );
};
