import { Breadcrumb, Button, Card, DatePicker, Form, Input, Select, Space, Table } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import Container from 'src/components/Container';
import { Icon } from '@iconify/react';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import HistoryPaymentCardGame from 'src/apis/service/cardGame';
import dayjs from 'dayjs';
import { FORMAT_TIME_TO_MINUTES } from 'src/constants/common';
import { CheckoutTypeOption, PaymentStatus } from 'src/constants/payment';
import { enqueueSnackbar } from 'notistack';
import { GET_LIST_ERROR_MESSAGE } from 'src/constants/message';
import { saveAs } from 'file-saver';
import gameCardRepository from 'src/apis/service/cardGame';

export default () => {
  const [dataCard, setDataCard] = useState<any>([]);
  const [filter, setFilter] = useState<any>({ limit: 10, page: 1 });
  const [totalItem, setTotalItem] = useState<number>(0);
  const [dataCp, setDataCp] = useState<any>([]);

  const { refetch } = useQuery(
    ['getHistoryPaymentCardGame', filter],
    () => HistoryPaymentCardGame.getHistoryPaymentCardGame(filter),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setDataCard(data?.data?.items);
        setTotalItem(data.data?.meta?.totalItems);
      },
    },
  );

  const pagination: TablePaginationConfig = {
    pageSize: filter.limit as number,
    current: filter.page as number,
    total: totalItem,
    onChange: (page: number, pageSize: number) => {
      setFilter({
        ...filter,
        limit: pageSize,
        page,
      });
    },
  };

  const handleSearchByKey = (key: string) => {
    if (key) {
      setFilter({ ...filter, page: 1, keyword: key });
    } else {
      setFilter({ ...filter, page: 1, keyword: undefined });
    }
  };

  const handleSearchWithPublishTime = (date: any) => {
    if (date) {
      setFilter({
        ...filter,
        page: 1,
        startTime: date ? dayjs(date[0]).startOf('day') : date,
        endTime: date ? dayjs(date[1]).endOf('day') : date,
      });
    } else {
      setFilter({
        ...filter,
        page: 1,
        startTime: undefined,
        endTime: undefined,
      });
    }
  };

  const onClickExportExcel = async (search: any) => {
    const filename = `Thong-ke-bao-cao-lich-su-mua-the-${dayjs(search.startDay).format('DD-MM-YYYY')}-den-${dayjs(
      search.endDay,
    ).format('DD-MM-YYYY')}`;

    try {
      const response = await gameCardRepository.getExportExcelGameCardPaymentHistory({ ...search });
      if (response) {
        const blob = new Blob([response], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
        });

        saveAs(blob, filename);
      }
    } catch (error) {
      enqueueSnackbar(GET_LIST_ERROR_MESSAGE, { variant: 'error' });
    }
  };

  useEffect(() => {
    refetch();
  }, [filter]);

  const columns: ColumnsType<any> = [
    {
      title: 'STT',
      dataIndex: 'index',
      key: 'index',
      render: (_, __, index) => <>{(filter.page - 1) * filter.limit + index + 1}</>,
    },
    {
      title: 'Transaction ID',
      dataIndex: 'transactionId',
      key: 'transactionId',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'UID',
      dataIndex: 'userId',
      key: 'userId',
    },
    {
      title: 'Mã sản phẩm',
      dataIndex: 'productCode',
      key: 'productCode',
    },
    {
      title: 'Tên sản phẩm',
      dataIndex: 'cardName',
      key: 'cardName',
    },
    {
      title: 'Mệnh giá ',
      dataIndex: 'price',
      key: 'price',
      render: (price) => <>{price?.toLocaleString('de-DE')}</>,
    },
    {
      title: 'Số lượng',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Giá trị thanh toán',
      dataIndex: 'chargePrice',
      key: 'chargePrice',
      render: (chargePrice) => <>{chargePrice?.toLocaleString('de-DE')}</>,
    },
    {
      title: 'Phương thức thanh toán ',
      dataIndex: 'checkoutType',
      key: 'checkoutType',
      render: (checkoutType) => <>{CheckoutTypeOption?.find((item) => item?.value === checkoutType)?.label}</>,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      render: (status) => <>{PaymentStatus?.find((item) => item?.value === status)?.label}</>,
    },
    {
      title: 'Ngày giao dịch',
      dataIndex: 'time',
      key: 'time',
      render: (time) => <>{dayjs(time).format(FORMAT_TIME_TO_MINUTES)}</>,
    },
  ];

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Breadcrumb
        items={[
          {
            title: (
              <p style={{ cursor: 'pointer' }} onClick={() => {}}>
                Mã thẻ
              </p>
            ),
          },
          {
            title: 'Lịch sử thanh toán',
          },
        ]}
      />

      <Card size="small">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            gap: '16px',
          }}
        >
          <div style={{ display: 'flex', gap: '20px' }}>
            <Form.Item
              label={<span>Tìm kiếm theo tên sản phẩm</span>}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input
                onChange={(e) => handleSearchByKey(e.target.value)}
                placeholder="Tìm kiếm theo tên sản phẩm"
                suffix={<Icon icon="ic:round-search" width={20} height={20} />}
              />
            </Form.Item>

            <Form.Item label={<span>Khoảng thời gian tìm kiếm</span>} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
              <DatePicker.RangePicker
                format={'DD/MM/YYYY'}
                style={{ width: '100%' }}
                onChange={(date) => handleSearchWithPublishTime(date)}
                inputReadOnly
              />
            </Form.Item>

            <Form.Item label={<span>Phương thức thanh toán</span>} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
              <Select
                style={{ width: '100%' }}
                onChange={(e) => setFilter({ ...filter, page: 1, checkoutType: e })}
                allowClear
                placeholder="Tất cả"
              >
                {CheckoutTypeOption?.map((cp: any, index) => (
                  <Select.Option key={index} value={cp.value}>
                    {cp.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label={<span></span>} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
              <Button type="primary" onClick={() => onClickExportExcel({ ...filter })}>
                Xuất excel
              </Button>
            </Form.Item>
          </div>
        </div>
      </Card>
      <Container title="Lịch sử thanh toán">
        <Table
          columns={columns}
          dataSource={dataCard}
          rowKey="_id"
          pagination={dataCard?.length > 0 ? pagination : false}
          scroll={{ x: 'max-content' }}
        />
      </Container>
    </Space>
  );
};
