import type * as Types from '../type.interface';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateCardMutationVariables = Types.Exact<{
  input: Types.UpdateGameCardDto;
}>;

export type UpdateCardMutationResponse = { __typename?: 'Mutation' } & {
  updateCard: { __typename?: 'GameCard' } & Pick<
    Types.GameCard,
    | '_id'
    | 'brandId'
    | 'brandName'
    | 'createdAt'
    | 'createdBy'
    | 'desc'
    | 'discountType'
    | 'discountValue'
    | 'isActive'
    | 'isDeleted'
    | 'name'
    | 'price'
    | 'productCode'
    | 'updatedAt'
    | 'updatedBy'
  > & {
      giftMobiGames?: Types.Maybe<{ __typename?: 'GiftMobiGames' } & Pick<Types.GiftMobiGames, 'energy' | 'gpoint'>>;
    };
};

export const UpdateCardDocument = gql`
  mutation updateCard($input: UpdateGameCardDto!) {
    updateCard(input: $input) {
      _id
      brandId
      brandName
      createdAt
      createdBy
      desc
      discountType
      discountValue
      giftMobiGames {
        energy
        gpoint
      }
      isActive
      isDeleted
      name
      price
      productCode
      updatedAt
      updatedBy
    }
  }
`;
export function useUpdateCardMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCardMutationResponse, UpdateCardMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCardMutationResponse, UpdateCardMutationVariables>(UpdateCardDocument, options);
}
export type UpdateCardMutationHookResult = ReturnType<typeof useUpdateCardMutation>;
export type UpdateCardMutationResult = Apollo.MutationResult<UpdateCardMutationResponse>;
export type UpdateCardMutationOptions = Apollo.BaseMutationOptions<
  UpdateCardMutationResponse,
  UpdateCardMutationVariables
>;
