import { callApi } from '../connection/callApi';

export default {
  getReportExcelSMSHistory(query: any): Promise<any> {
    return callApi({
      method: 'get',
      uri: '/cms/strategy-sms/send-history/export-excel',
      query,
      responseType: 'blob',
    });
  },
};
